@media print {
  @page {
    size: A4 portrait;   /* auto is the initial value */
    margin: 0 !important;
    padding: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .container_spacer {
    padding: 0 7.1mm;
  }

  .no-print {
    display: none;
  }

  .no-screen {
    display: block !important;
  }

  .print {
    &__layout_main {
      padding: 0;
    }

    &__block {
      page-break-inside: avoid;
      //page-break-after: always;
      //page-break-before: always;
    }

    &__body-text {
      font-family: var(--font);
      font-style: normal;
      font-weight: 400;
      font-size: 9pt;
      line-height: 140%;
      color: black;
      font-stretch: 110%;
      font-variation-settings: var(--font-settings);

      &_grey {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &__point-body-text {
      font-weight: 500;
      line-height: 110%;
      text-transform: uppercase;
      font-family: var(--font);
      font-style: normal;
      font-size: 8pt;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }

    &__bold-text-small {
      font-family: var(--font);
      font-style: normal;
      font-weight: 600;
      font-size: 10pt;
      line-height: 120%;
      text-transform: uppercase;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }

    &__bold-text-medium {
      font-family: var(--font);
      font-style: normal;
      font-size: 12.5pt;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 110%;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }

    &__bold-text {
      font-family: var(--font);
      font-style: normal;
      font-weight: 600;
      font-size: 17.5pt;
      line-height: 120%;
      text-transform: uppercase;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }

    &__bold-text-large {
      font-family: var(--font);
      font-style: normal;
      font-weight: 600;
      font-size: 22.5pt;
      line-height: 120%;
      text-transform: uppercase;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }
  }
}

.no-screen {
  display: none;
}