.request-form {
  &.success {
    .request-form-success {
      &__icon,
      &__title,
      &__subtitle {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      &__icon {
        svg line {
          &:nth-child(1) {
            stroke-dashoffset: 0;
          }

          &:nth-child(2) {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }

  &__success {
    flex-direction: column;
  }

  .request-form-success {
    &__icon,
    &__title,
    &__subtitle {
      transition: opacity .5s, transform .5s;
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    &__icon {
      transition-delay: .3s;

      svg line {
        &:nth-child(1) {
          transition: stroke-dashoffset .2s .5s;
          stroke-dashoffset: 40;
        }

        &:nth-child(2) {
          transition: stroke-dashoffset .5s .7s;
          stroke-dashoffset: 95;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .request-form-success {
    &__title {
      margin-top: 40px;
    }

    &__subtitle {
      margin-top: 40px;
      width: var(--grid-column2);
    }
  }
}

@include respond('medium') {
  .request-form-success {
    &__title {
      margin-top: 30px;
    }

    &__subtitle {
      margin-top: 30px;
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .request-form-success {
    &__icon {
      svg {
        width: 82px;
        height: 82px;
      }
    }

    &__title {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 20px;
    }
  }
}