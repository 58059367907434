.flat-blocks {
  &__button-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__item-title {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;

    &.animate {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__inner {
    
  }
}

@include respond-up('s-large') {
  .flat-blocks {
    margin-top: 40px;
    padding-bottom: 200px;

    &__list {
      margin-top: 135px;
    }

    &__item {
      &:first-child {
        grid-column: 2/4;
      }

      &:nth-child(2) {
        grid-column: 4/6;
      }

      &:nth-child(3) {
        grid-column: 6/8;
      }
    }

    &__item-title {
      margin-top: 50px;
    }

    &__item-description {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .flat-blocks {
    margin-top: 30px;
    padding-bottom: 120px;

    &__list {
      margin-top: 100px;
      grid-row-gap: 0;
    }

    &__item {
      grid-column: 1/6;
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 90px;
      }
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__item-icon {
      svg {
        width: 214px;
        height: 171px;
      }
    }

    &__item-description {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .flat-blocks {
    margin-top: 20px;
    padding-bottom: 60px;

    &__list {
      margin-top: 50px;
      grid-row-gap: 0;
    }

    &__item {
      grid-column: 1/5;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__item-icon {
      svg {
        width: 135px;
        height: 108px;
      }
    }

    &__item-description,
    &__item-title {
      margin-top: 20px;
    }
  }
}