// Тема - как выглядят те или иные элементы
.genplan {
  position: relative;

  --preloader-bg-color: #fff;
  --preloader-color: #000;

  &._layout-page {
    width: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));
    height: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));

    .genplan {
      &__layer-substrate,
      &__layer-polygon {
        svg {
          path {
            transition: all 0.4s;
            fill: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  &__place-mark {
    padding: 4px 13px;
    color: black;
    background: white;
    border-radius: 100px;
  }

  [data-gen-loader] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2000;
    background-color: var(--t);
    transition: background-color, opacity;
    transition-duration: 0.3s;
    transition-timing-function: var(--default-transition-function);
    opacity: 0;
    visibility: hidden;

    .genplan-preloader-icon {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity .3s var(--default-transition-function);
    }

    &._opened {
      background-color: var(--preloader-bg-color);
      opacity: 1;
      visibility: visible;
    }
  }

  &__minimap {
    height: 50px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    border: 2px solid #fff;
    border-radius: 2px;
    z-index: 1000;
    padding: 4px;
  }

  &__minimap-bar {

  }

  &__minimap-handle {
    border-radius: 2px;
    background-color: #fff;
  }

  // Позиционирование балуна на мобильнике
  .genplan__object-balloon._modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1000;
  }

  // Балун на десктопе
  &__object-balloon._popover {
    background-color: #fff;
    padding: 20px;
    transition: all 0.3s;
    border: 1px solid black;

    .genplan__balloon {
      width: max-content;
    }

    // Отступ балуна от точки привязки
    --balloon-offset: 10px;
    // Цвет "Ножки балуна"
    --balloon-pin-color: #fff;

    &.to-right {
      margin-left: var(--balloon-offset);

      //&:before {
      //  left: -8px;
      //  border-width: 8px 8px 8px 0;
      //  border-color: transparent var(--balloon-pin-color) transparent transparent;
      //}
    }

    &.to-left {
      margin-right: var(--balloon-offset);

      //&:before {
      //  right: -8px;
      //  border-width: 8px 0 8px 8px;
      //  border-color: transparent transparent transparent var(--balloon-pin-color);
      //}
    }

    &.to-top:before {
      bottom: var(--pin-y-offset);
    }

    &.to-bottom:before {
      top: var(--pin-y-offset);
    }

    //&:before {
    //  position: absolute;
    //  content: '';
    //  width: 0;
    //  height: 0;
    //  border-style: solid;
    //}

    .genplan {
      &__list {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__item {
        padding: 10px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;

        &:not(:last-child) {
          border-bottom: 1px dashed black;
        }
      }

      &__price {
        margin-top: 34px;
      }
    }
  }

  &__scene-modals {
    // Черная подложка над картой на мобильнике, когда открыт балун
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.4s;
    }

    &._opened:before {
      opacity: 1;
    }
  }

  // Переходы и прозрачности для полигонов
  &__layer-substrate,
  &__layer-polygon {
    svg {
      path {
        transition: all 0.4s;
          fill: rgba(0, 0, 0, 0.2);
        &._hover {
          fill: rgba(0, 0, 0, 0.3);
        }

        &:hover {
          fill: rgba(0, 0, 0, 0.3);
        }

        &._sold {
          fill: rgba(235, 235, 235, 0.9);
        }
      }
    }
  }

  &__scene._scrollable {
    .genplan {
      &__layer-substrate,
      &__layer-polygon {
        svg {
          path {
            transition: all 0.4s;
            opacity: 0.5;

            &._hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  &__scenes {
    transform: translate3d(0, 0, 0);
  }

  &__scene {
    &._show {
      animation-name: gen-scene-show;
      animation-fill-mode: forwards;
      animation-duration: 0.7s;
    }

    &._hide {
      animation-name: gen-scene-hide;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    }
  }
}

@include respond-down('medium') {
  .genplan {
    &._layout-page {
      width: calc(var(--grid-column5) * 0.8 + 2 * var(--grid-gap));
      height: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));
    }

    // Переходы и прозрачности для полигонов
    &__layer-substrate,
    &__layer-polygon {
      svg {
        path {
          transition: all 0.4s;
          fill: rgba(0, 0, 0, .3);
        }
      }
    }

    .genplan__scene-container {
      &::before {
        content: '';
        position: absolute;
        z-index: 2000;
        top: 50%;
        left: 50%;
        transform: translate3d(calc(-50% - 26px), -50%, 0);
        width: 53px;
        height: 53px;
        background-image: url("../images/svg/swipe.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        opacity: 0;
        animation: gen-loader-swipe-animation 1.4s .7s var(--default-transition-function) forwards;
      }
    }

    .genplan__layers {
      animation: gen-loader-swipe-content 1.2s 1s var(--default-transition-function) forwards;
    }
  }
}

@include respond-down('small') {
  .genplan {
    &._layout-page {
      width: calc(var(--grid-column5) * 0.8 + 2 * var(--grid-gap));
      height: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));
    }
  }
}

@keyframes gen-scene-hide {
  from {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2);
  }
}

@keyframes gen-scene-show {
  from {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

@keyframes gen-loader-animation {
  from {
    r: 0;
  }

  50% {
    r: 3.5;
  }

  to {
    r: 0;
  }
}

@keyframes gen-loader-swipe-animation {
  from {
    opacity: 0;
    transform: translate3d(calc(-50% - 26px), -50%, 0);
  }

  28% {
    opacity: 1;
  }

  50% {
    opacity: 1;
    transform: translate3d(calc(-50% + 26px), -50%, 0);
  }

  72% {
    opacity: 1;
  }

  to {
    transform: translate3d(calc(-50% - 26px), -50%, 0);
    opacity: 0;
  }
}

@keyframes gen-loader-swipe-content {
  from {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(80px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}