/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$largeMinWidth: 1200;

$grid: (
        x-small: (
                form: 0,
                to: 319
        ),
        small: (
                from: 320,
                to: 767
        ),
        medium: (
                from: 768,
                to: 1199
        ),
        s-large: (
                from: 1200,
                to: 1599
        ),
        large: (
                from: 1600,
                to: 1920
        ),
        x-large: (
                from: 1921
        )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Roboto Flex", sans-serif;
  --h-font: "Roboto Flex", sans-serif;

  --font-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79,
  'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;

  --t: transparent;

  --primary-color: black;
  --background-color: #f0f0f0; // серая подлога
  --alert-color: #FF6969;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity-40: rgba(0, 0, 0, .4);
  --primary-color-opacity: rgba(0, 0, 0, .85);

  --default-timing: .4s;
  --long-timing: .8s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);

  --large-min-width: #{$largeMinWidth};
}
