.contacts {
  &.animate {
    .contacts {
      &__title,
      &__phone,
      &__address {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__blocks-wrap {
     grid-row-gap: 0;
  }

  &__title-wrapper {
    overflow: hidden;
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__phone,
  &__address {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;
  }

  &__wrap {
    grid-row-gap: 0;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: -20px;
  }

  &__link-text {
    margin-left: 12px;
  }

  &__schedule-item {
    display: flex;
    padding-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px dashed black;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__schedule-day {
    text-transform: uppercase;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  &__schedule-time {
    margin-left: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .contacts {
    margin-top: 80px;
    border-bottom: 1px solid black;

    &__link {
      grid-column: 1/2;

      svg {
        transition: var(--default-transition);
      }

      &:hover {
        svg {
          transform: translateX(-10px);
        }
      }
    }

    &__link-text {
      margin-left: 12px;
    }

    &__title-wrapper {
      grid-column: 1/5;
      margin-top: 20px;
    }

    &__blocks-wrap {
      grid-column: 1/8;
      margin-top: 50px;
    }

    &__info-block {
      grid-column: 1/4;
      position: relative;
      padding-top: 100px;
      padding-bottom: 40px;

      &:before {
        position: absolute;
        content: '';
        background: black;
        height: 1px;
        top: 0;
        left: 0;
        width: calc(100% + var(--grid-gap));
      }
    }

    &__contacts {
      width: var(--grid-column3);
    }

    &__email {
      margin-top: 12px;
    }

    &__address {
      margin-top: 50px;
      width: var(--grid-column3);
    }

    &__social-wrap {
      margin-top: 50px;
    }

    &__schedule-list {
      margin-top: 70px;
    }

    &__schedule-item {
      width: var(--grid-column2);
    }

    &__map-block {
      grid-column: 4/8;
      padding: 0 0 40px 40px;
      border-left: 1px solid black;
    }
  }
}

@include respond-up('medium') {
  .contacts {
    &__email {
      margin-top: 12px;
    }

    &__schedule-day {
      flex: 0 0 var(--grid-column);
    }
  }
}

@include respond('medium') {
  .contacts {
    margin-top: 60px;

    &__link {
      grid-column: 1/2;
    }

    &__title-wrapper {
      grid-column: 1/4;
      margin-top: 15px;
    }

    &__blocks-wrap {
      grid-column: 1/6;
      margin-top: 30px;
      padding-top: 50px;
      padding-bottom: 40px;
      border-top: 1px solid black;
      grid-row-gap: 0;
    }

    &__info-block {
      grid-column: 1/6;
    }

    &__contacts {
      width: var(--grid-column3);
    }

    &__address {
      width: var(--grid-column4);
      margin-top: 40px;
    }

    &__social-wrap {
      margin-top: 40px;
    }

    &__schedule-list {
      margin-top: 50px;
    }

    &__schedule-item {
      width: var(--grid-column3);
    }

    &__map-block {
      grid-column: 1/6;
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .contacts {

  }
}

@include respond-down('small') {
  .contacts {
    margin-top: 20px;

    &__link {
      grid-column: 1/3;
    }

    &__title-wrapper {
      grid-column: 1/4;
      margin-top: 14px;
    }

    &__blocks-wrap {
      margin-top: 14px;
      padding: 30px 0 20px 0;
      border-top: 1px solid black;
      grid-column: 1/5;
    }

    &__info-block {
      grid-column: 1/5;
    }

    &__email {
      margin-top: 5px;
    }

    &__address {
      margin-top: 30px;
    }

    &__social-wrap {
      margin-top: 30px;
    }

    &__schedule-list {
      margin-top: 30px;
    }

    &__schedule-day {
      flex: 0 0 var(--grid-column2);
    }

    &__map-block {
      grid-column: 1/5;
      margin-top: 30px;
    }
  }
}