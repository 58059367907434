.about-slider {
  &__slide {
    &._active {
      .about-slider {
        &__main-text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__main-text {
    position: relative;
  }

  &__main-text-inner {
    display: block;
    //white-space: nowrap;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: calc(((#{$i} - 1) * 0.1s));
      }
    }
  }

  &__slide-name {
    overflow: hidden;

    &.animate {
      .about-slider__title {
        span {
          transform: translateY(0);
        }
      }
    }
  }

  &__title {
    span {
      transition: transform 0.8s var(--teaser-transition-func);
    }
  }

  &__picture {
    width: 100%;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@include respond-up("s-large") {
  .about-slider {
    margin-top: 30px;

    &__picture {
      height: 100%;
    }

    &__slide-text {
      color: black;
    }

    &__slide-counter {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__slide-counter-delimiter {
      margin: 0 4px;
    }

    &._scroller {
      .about-slider {
        transform: translate3d(0, 0, 0);

        &__slides-container {
          height: calc(100vh * var(--slides-count));
        }

        &__slides {
          position: sticky;
          height: 100vh;
          top: 0;
        }

        &__slide {
          position: absolute;
          height: 100vh;
          overflow: hidden;
        }

        &__slide-row {
          padding-top: 80px;
          height: 100vh;
        }

        &__slide-content {
          position: relative;
          display: flex;
        }

        &__slide-counter {
          position: absolute;
          bottom: var(--grid-gap);
          right: 0;
        }

        &__slide-image {
          overflow: hidden;
        }

        &__picture, &__image {
          display: block;
        }

        &__picture {
          transition: all 0.1s;
          clip-path: polygon(0 0, 0 var(--percent-rev), 100% var(--percent-rev), 100% 0);

          position: relative;

          // Нахер верстальщикам математика?
          // Не нужна. Пойду просто верстать.
          // Блоки там всякие
          // Мы чо на уроке математики чтоли?
          // Да не, бред какой-то

          --scale: calc(1 + (0.2 - var(--next-percent-val) * 0.002));
          --offset: calc(300px - (var(--next-percent-val) / 100 * 300px));
          overflow: hidden;

          .about-slider__image {
            transition: all 0.1s;
            transform: scale(var(--scale)) translate3d(0, var(--offset), 0);
          }

          //&:before {
          //  transition: all 0.1s;
          //  content: '';
          //  position: absolute;
          //  width: 100%;
          //  height: 5px;
          //  background: white;
          //  left: 0;
          //  right: 0;
          //  top: 5px;
          //  transform: translate3d(0, calc((100vh - 150px) - var(--percent-val) / 100 * (100vh - 140px)), 0);
          //  z-index: 1500;
          //}
        }

        &__slide {
          .about-slider__slide-counter,
          .about-slider__slide-name,
          .about-slider__slide-text {
            animation-fill-mode: forwards;
            animation-duration: 0.5s;
          }

          .about-slider__slide-counter,
          .about-slider__slide-name,
          .about-slider__slide-text {
            opacity: 0;
          }

          &._active {
            .about-slider__slide-counter {
              animation-delay: 0.3s;
            }

            .about-slider__slide-name {
              animation-delay: 0.3s;
            }

            .about-slider__slide-text {
              animation-delay: 0.3s;
            }
          }

          &._active_up {
            .about-slider__slide-counter {
              animation-name: scroller-active-up;
            }

            .about-slider__slide-name,
            .about-slider__slide-text {
              animation-name: scroller-active-up;
            }
          }

          &._active_down {
            .about-slider__slide-counter {
              animation-name: scroller-active-down;
            }

            .about-slider__slide-name,
            .about-slider__slide-text {
              animation-name: scroller-active-down;
            }
          }

          &._inactive_up {
            .about-slider__slide-counter {
              animation-name: scroller-inactive-up;
            }

            .about-slider__slide-name,
            .about-slider__slide-text {
              animation-name: scroller-inactive-up;
            }
          }

          &._inactive_down {
            .about-slider__slide-counter {
              animation-name: scroller-inactive-down;
            }

            .about-slider__slide-name,
            .about-slider__slide-text {
              animation-name: scroller-inactive-down;
            }
          }
        }
      }
    }

    &__slide-text {
      margin-top: 70px;
      width: var(--grid-column2);
    }

    &__slider {
      height: 825px;
      position: relative;
    }

    &__slide-content {
      padding-bottom: var(--grid-gap);
      padding-top: 125px;
      grid-column: 1/4;
    }

    &__main-text {
      width: var(--grid-column3);
    }

    &__slide-row {
      grid-column: 1/8;
    }

    &__slide-image {
      grid-column: 4/8;
      padding: 0 0 var(--grid-gap) var(--grid-gap);
    }

    &__slides-container {
      position: relative;
      border-bottom: 1px solid black;

      &:before {
        content: '';
        position: absolute;
        background: black;
        height: calc(100% - 80px);
        width: 1px;
        left: calc(var(--grid-column3) + var(--grid-gap));
        bottom: 0;
      }
    }

    &__slide-content-container {
      position: relative;
    }

    &__arrow {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

@include respond("medium") {
  .about-slider {
    margin-top: 70px;

    &__slide {
      padding-top: 30px;
      border-top: 1px solid black;
      padding-bottom: 70px;

      &:last-child {
        border-bottom: 1px solid black;
      }
    }

    &__slide-row {
      grid-row-gap: 0;
      position: relative;
      grid-template-areas:
        "number image image image image"
        "number text text text text";
    }

    &__slide-image {
      grid-area: image;
      height: calc(var(--grid-column4) * 396 / 597);
    }

    &__slide-content {
      grid-area: text;
      margin-top: 50px;
    }

    &__slide-text {
      margin-top: 40px;
    }

    &__slide-counter {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include respond-down("medium") {
  .about-slider {

    &__main-text {
      position: relative;

      &.animate {
        .about-slider {
          &__main-text-inner {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &__slide-counter-delimiter {
      display: none;
    }

    &__slide-counter-value {
      &_total {
        display: none;
      }
    }

    &__slides {
      flex-direction: column;
    }
  }
}

@include respond-down("small") {
  .about-slider {
    margin-top: 30px;

    &__main-text {
      width: 95%;
    }

    &__slide {
      border-bottom: 1px solid black;
      padding-bottom: 30px;
    }

    &__slide {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__slide-row {
      grid-row-gap: 0;
      grid-template-areas:
        "image image image image"
        "text text text text";
    }

    &__slide-image {
      grid-area: image;
      height: calc(var(--grid-column4) * 222 / 334);
    }

    &__slide-content {
      grid-area: text;
      margin-top: 20px;
      position: relative;
    }

    &__slide-text {
      margin-top: 20px;
    }

    &__slide-counter {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

$offsetMove: 30px;
@keyframes scroller-active-up {
  from {
    transform: translate3d(0, -$offsetMove, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes scroller-active-down {
  from {
    transform: translate3d(0, $offsetMove, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes scroller-inactive-up {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, $offsetMove, 0);
    opacity: 0;
  }
}

@keyframes scroller-inactive-down {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -$offsetMove, 0);
    opacity: 0;
  }
}
