.apartments-list {
  &__item {
    background: var(--background-color);
    margin-top: 4px;

    &:first-child {
      margin: 0;
    }
  }

  &__item-part {
    display: flex;
  }

  &__title {
    @extend h5
  }

  &__floor-value {
    @extend h2
  }

  &__price {
    @extend h4
  }
}

@include respond-up('s-large') {
  .apartments-list {
    margin-top: 40px;

    &__title {
      grid-column: 1 / 3;
    }

    &__items {
      grid-column: 3 / 8;
    }

    &__item {
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 15px 40px;
    }

    &__item-part {
      display: flex;
      align-items: center;
    }

    &__floor-value {
      width: 130px;
    }

    &__renovation-title {
      display: none;
    }

    &__article-title{
      display: none;
    }

    &__renovation {
      //margin-left: 153px;
    }
  }
}

@include respond-up('medium') {
  .apartments-list {

  }
}

@include respond('medium') {
  .apartments-list {
    margin-top: 120px;

    &__items {
      grid-column: 1 / 6;
    }

    &__item {
      padding: 30px 30px 20px 30px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: flex-start;
    }

    &__item-part {
      &_price {
        align-self: center;
        grid-column: 5 / 6;
      }
    }
  }
}

@include respond-down('medium') {
  .apartments-list {
    &__title {
      grid-column: 1 / 5;
      grid-row: 1 / 2;
    }

    &__items {
      grid-row: 2 / 3;
    }

    &__renovation-title, &__floor-name, &__article-title {
      color: var(--primary-color-opacity-40);
    }

    &__item-part {
      display: flex;

      &_floor {
        flex-direction: column-reverse;
      }

      &_renovation {
        flex-direction: column;
      }
    }
  }
}

@include respond-down('small') {
  .apartments-list {
    margin-top: 60px;

    &__items {
      grid-column: 1 / 5;
    }

    &__item {
      padding: 15px 20px;
      display: grid;
      row-gap: 20px;
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
    }

    &__item-part {
      &_floor {
        grid-column: 1 / 3;
      }

      &_renovation {
        grid-column: 3 / 6;
      }

      &_price {
        align-self: center;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
      }

      &__article{
        grid-row: 2 / 3;
      }
    }
  }
}