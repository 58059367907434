.trim {
  grid-row-gap: 0;

  &.animate {
    .trim {
      &__title-text-inner,
      &__title {
        transform: translateY(0);
      }
    }
  }

  &__tabs {
    &.tabs__third {
      .tabs__list {
        .tabs__item {
          .tabs__link {
            transform: translateY(100%);
            transition: transform .8s, opacity .8s;
            transition-timing-function: var(--teaser-transition-func);
            .tabs__text {
              transition: transform .8s, opacity .8s, color .4s;
              transition-timing-function: var(--teaser-transition-func);
              opacity: 0;
            }
          }
        }
      }
    }

    &.animate {
      .tabs__list {
        .tabs__item {
          .tabs__link {
            transform: translateY(0);
            .tabs__text {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__title-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);

    @for $i from 2 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }
  }

  &__title-wrap{
    overflow: hidden;
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__picture-wrapper {
    position: relative;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__dot-pulse {
    height: 42px;
    width: 42px;
    background: black;
    position: absolute;
    border-radius: 24px;
    top: 0;
    left: 0;
    //transform: translate3d(-50%, -50%, 0);
    //opacity: 0;
  }

  &__dot {
    height: 42px;
    width: 42px;
    background: black;
    position: absolute;
    border-radius: 21px;
  }

  &__dot-cross {
    position: relative;
    height: 42px;
    width: 42px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      height: 2px;
      width: 20px;
      background: white;
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &__list {
    width: var(--grid-column4);
    display: flex;
  }

  &__item {
    display: flex;
    border-bottom: 1px dashed black;
  }
}

@include respond-up("s-large") {
  .trim {
    margin-top: 200px;

    &__title-wrap {
      position: sticky;
      top: 100px;
      background: white;
      z-index: 100;
    }

    &__tabs {
      margin-top: 70px;
      position: sticky;
      top: 313px;
    }

    &__picture {
      height: calc((var(--grid-column4) - var(--grid-gap)) * 566 / 926);
    }

    &__tabs-wrapper {
      grid-column: 1/4;
      padding-top: 112px;
    }

    &__content-wrapper {
      grid-column: 4/8;
    }

    &__picture-wrap {
      border-left: 1px solid black;
      padding-left: var(--grid-gap);
    }

    &__list {
      margin-top: 90px;
    }

    &__item {
      align-items: flex-start;
      padding-bottom: 30px;
      margin-top: 60px;
    }

    &__description {
      margin-left: 40px;
    }
  }
}

@include respond("s-large") {
  .trim {
  }
}

@include respond-down("s-large") {
  .trim {
  }
}

@include respond-up("medium") {
  .trim {
    &__dot-description {
      padding: 4px 8px;
      background: white;
      border: 1px solid black;
      position: absolute;
      white-space: nowrap;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-transition-function);
    }

    &__dot-pulse {
      opacity: 1;
      transform-origin: 50% 50%;
      animation-duration: 1.4s;
      animation-name: dotpulse;
      animation-iteration-count: infinite;
    }

    &__dot {
      &:hover {
        .trim {
          &__dot-description {
            opacity: 1;
          }
        }
      }
    }

    &__list {
      flex-wrap: wrap;
    }

    &__item {
      width: var(--grid-column2);

      &:nth-child(even) {
        margin-left: var(--grid-gap);
      }

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

@include respond("medium") {
  .trim {
    margin-top: 120px;

    &__picture {
      height: calc(var(--grid-column5) * 462 / 754);
    }

    &__tabs {
      margin-top: 10px;
    }

    &__content-wrapper {
      margin-top: 30px;
    }

    &__tabs-wrapper,
    &__content-wrapper {
      grid-column: 1/6;
    }

    &__list {
      margin-top: 50px;
    }

    &__item {
      flex-direction: column;
      padding-bottom: 20px;
      margin-top: 30px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down("medium") {
  .trim {
  }
}

@include respond-down("small") {
  .trim {
    margin-top: 60px;

    &__picture {
      height: calc(var(--grid-column4) * 205 / 334);
    }

    &__content-wrapper {
      margin-top: 14px;
    }

    &__tabs-wrapper,
    &__content-wrapper {
      grid-column: 1/5;
    }

    &__dot {
      display: none;
    }

    &__list {
      flex-direction: column;
      margin-top: 30px;
    }

    &__item {
      width: var(--grid-column4);
      padding-bottom: 14px;
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    &__description {
      margin-left: 57px;
    }
  }
}

@keyframes dotpulse {
  from {
    opacity: 1;
    transform: scale(0.4);
  }
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}