.comfort {
  &.animate {
    .comfort__heading,
    .comfort__heading-text-inner {
      transform: translateY(0);
    }

    .comfort__main-text {
      .hero__main-text {
        &:before {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .hero__main-text-inner {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__heading-wrap {
    overflow: hidden;
  }

  &__heading {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__heading-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);

    @for $i from 2 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }
  }

  &__content {
    overflow: hidden;
    height: 0;
    transition: var(--default-transition);
  }

  &__wrap {
    grid-row-gap: 0;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__list {
    overflow: hidden;
  }

  &__link {
    display: block;
    position: relative;

    &.h5 {
      line-height: 130%;
    }

    &:not(:last-child) {
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }

  &__link-text {
    position: relative;
    display: flex;
    padding-right: calc(20px + var(--grid-gap));

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: black;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      transition: var(--default-transition);
    }

    &:after {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &__item {
    transition: var(--default-transition);

    &._opened {
      .comfort {
        &__link-text {
          &:after {
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &__list-wrapper {
    //TODO: make a custom scroll bar
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@include respond-up("large") {
  .comfort {
    // some stuff was for version with the scrollbar. but the latest cant be used because of windows. bummer.
    &__list-wrapper {
      width: calc(100% + 40px);

      &:before {
        width: calc(100% - var(--grid-gap));
      }
    }

    &__accordion-picture-wrapper,
    &__list-wrapper {
      height: calc((797 / 1176) * (var(--grid-column5) - 40px) + 40px);
    }

    &__picture-desktop {
      width: calc(var(--grid-column5) - 40px);
      height: calc((797 / 1176) * (var(--grid-column5) - 40px));
    }

    &__list {
      width: calc(var(--grid-column2) + 40px);
    }

    &__list-wrapp {
      margin-right: calc(-20px - var(--grid-gap));

      &:after {
        right: calc(20px + var(--grid-gap));
      }

      &:before {
        width: calc(100% - 20px - var(--grid-gap));
      }
    }
  }
}

@include respond-up("s-large") {
  .comfort {
    padding-top: 200px;

    &__item {
      &._opened {
        flex: 0 0 auto;
      }
    }

    &__picture-mobile {
      display: none;
    }

    &__accordion-picture-item {
      &._visible {
        .comfort {
          &__image-desktop {
            transform: scale(1);
          }

          &__picture-desktop {
            opacity: 1;
          }
        }
      }
    }

    &__accordion-picture-wrapper {
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 1.2s var(--teaser-transition-func);
        z-index: 100;
      }

      &.animate {
        &:before {
          transform: translateY(-100%);
        }
      }

      &.animate {
        .comfort {
          &__accordion-picture-item {
            &:first-child {
              .comfort {
                &__picture {
                  opacity: 1;
                }

                &__image-desktop {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
    }

    &__list-wrapp {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        top: 0;
      }
    }

    &__list-wrapper {
      position: relative;
      border-left: 1px solid black;
      overflow: hidden scroll;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        background: black;
        top: 0;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__link {
      padding-left: 40px;
    }

    &__picture-desktop {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition-property: opacity;
      transition-timing-function: var(--teaser-transition-func);
      transition-duration: .5s;
    }

    &__image-desktop {
      transform: scale(1.1);
      transition-property: transform;
      transition-duration: 7s;
      transform-origin: center center;
      transition-timing-function: var(--teaser-transition-func);
    }

    &__accordion-picture-wrapper {
      width: var(--grid-column5);
      position: relative;
    }

    &__heading-wrap {
      grid-column: 1/4;
    }

    &__accordion-wrapper {
      margin-top: 100px;
      display: flex;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        bottom: 0;
      }
    }

    &__content-inner {
      padding: 30px 0 40px 40px;
    }
  }
}

@include respond("s-large") {
  .comfort {
    &__list-wrapp {
      &:after {
        right: 20px;
      }

      &:before {
        width: 100%;
      }
    }

    &__list-wrapper {
      width: 100%;

      &:before {
        width: calc(100% - 20px);
      }
    }

    &__accordion-picture-wrapper,
    &__list-wrapper {
      height: calc((612 / 680) * var(--grid-column4) + var(--grid-gap));
    }

    &__picture-desktop {
      width: calc(var(--grid-column4));
      height: calc((612 / 680) * (var(--grid-column4)));
    }

    &__list-wrapp,
    &__list {
      width: var(--grid-column3);
    }
  }
}

@include respond-up("medium") {
  .comfort {
    &__link {
      padding-top: 29px;
      padding-bottom: 27px;
    }
  }
}

@include respond("medium") {
  .comfort {
    padding-top: 120px;

    &__picture-mobile {
      width: var(--grid-column5);
      height: calc(var(--grid-column5) * (443 / 724));
    }

    &__link {
      padding-left: 30px;
    }

    &__accordion-wrapper {
      margin-top: 70px;
    }

    &__main-text {
    }

    &__content-inner {
      padding: 0 0 30px 30px;
    }

    &__text {
      margin-top: 30px;
      width: calc(var(--grid-column4) - 30px);
    }
  }
}

@include respond-down("medium") {
  .comfort {
    &__accordion-wrapper {
      border-left: 1px solid black;
    }

    &__picture-desktop {
      display: none;
    }

    &__heading-wrap {
      grid-column: 1/5;
    }

    &__list {
      border-bottom: 1px solid black;
    }
  }
}

@include respond-down("small") {
  .comfort {
    padding-top: 60px;

    &__accordion-wrapper {
      margin-top: 30px;
    }

    &__link {
      padding-top: 20px;
      padding-bottom: 18px;
    }

    &__link {
      padding-left: 20px;
    }

    &__picture-mobile {
      width: calc(var(--grid-column4) - 20px);
      height: calc((var(--grid-column4) - 20px) * (192 / 314));
    }

    &__content-inner {
      padding: 0 0 20px 20px;
    }

    &__text {
      margin-top: 20px;
    }
  }
}
