.popup-modal {
  &__form,
  ._hide {
    display: none;
  }

  ._show {
    display: block;
  }

  &__wrap {
    display: flex;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('medium') {
  .popup-modal {
    &__form {
      &._show {
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &__wrap {
      height: 100%;
    }

    &__info {
      padding: 40px;
      flex: 0 0 519px;
    }

    &__image-wrapper {
      flex: 0 0 279px;
      margin-top: 1px;
    }

    &__picutre {
      width: 279px;
      height: 368px;
    }
  }
}

@include respond-down('small') {
  .popup-modal {
    &__info {
      padding-top: 30px;
    }

    form {
      padding-top: 40px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__image-wrapper {
      flex: 0 0 96px;
    }

    &__picture {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}