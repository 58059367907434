body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
  //
  //&::-webkit-scrollbar {
  //  display: none;
  //}
}

input, button, form {
  font-family: var(--font);
}