.popup-modal-success {
  &__icon,
  &__title,
  &__subtitle {
    transition: opacity .5s, transform .5s;
  }

  &__icon {
    transition-delay: .3s;

    svg line {
      &:nth-child(1) {
        transition: stroke-dashoffset .2s .5s;
      }

      &:nth-child(2) {
        transition: stroke-dashoffset .5s .7s;
      }
    }
  }

  &._awaiting-animation {
    .popup-modal-success {
      &__icon,
      &__title,
      &__subtitle {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }

      &__icon {
        svg line {
          &:nth-child(1) {
            stroke-dashoffset: 40;
          }

          &:nth-child(2) {
            stroke-dashoffset: 95;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .popup-modal-success {
    &__title {
      margin-top: 40px;
    }

    &__subtitle {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .popup-modal-success {
    z-index: 10;
    position: absolute;
    background: white;
    padding-top: 60px;

    &__title,
    &__subtitle {
      margin-top: 20px;
    }
  }
}