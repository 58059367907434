.footer {
  background: var(--background-color);

  &__bottom-wrap {
    grid-row-gap: 0;
  }

  &__kssk-logo,
  &__prime-logo {
    display: flex;
    align-items: center;
  }

  &.animate {
    .footer {
      &__title-text-inner,
      &__title {
        transform: translateY(0);
      }

      &__address,
      &__phone {
        span {
          transform: translateY(0);
        }
      }

      &__address,
      &__phone {
        span {
          opacity: 1;
        }
      }
    }
  }

  &__phone,
  &__address {
    overflow: hidden;
  }

  &__phone,
  &__address {
    span {
      display: flex;
      transition: transform .8s, opacity .8s;
      transition-timing-function: var(--teaser-transition-func);
      transform: translateY(100%);
      opacity: 0;
    }
  }

  &__title-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);

    @for $i from 2 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }
  }

  &__title-wrap {
    overflow: hidden;
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__bottom-left-wrap {
    display: flex;
  }

  &__kssk-logo {
    svg {
      path {
        fill: black;
      }
    }
  }

  .footer {
    &__field {
      .footer {
        &__input {
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset;
          }
        }
      }
    }
  }

  &__form-policy-wrap {
    color: var(--primary-color-opacity-40);
  }

  &__form-policy-link {
    border-bottom: 1px solid var(--primary-color-opacity-40);
  }

  &__input {
    background: var(--background-color);
  }

  &__wrap {
    grid-row-gap: 0;
  }

  &__rclass {
    color: var(--primary-color-opacity-40);
  }

  &__rclass-link {
    color: black;
    margin-left: 1px;
  }

  &__public-offer{
    padding-top: 30px;
  }

  &__public-offer-text{
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font);
    grid-column: 1/5;
    line-height: 140%;
  }
}

@include respond-up('s-large') {
  .footer {
    margin-top: 90px;
    padding-top: 60px;
    padding-bottom: 90px;

    &__bottom-left-wrap {
      grid-column: 1/4;
    }

    &__bottom-wrap {
      grid-column: 1/8;
    }

    &__policy {
    }

    &__left-wrap {
      grid-column: 1/5;
      border-right: 1px solid black;
      padding-top: 120px;
      padding-bottom: 40px;
    }

    &__title-wrap {
      max-width: var(--grid-column3);
    }

    &__address-wrap {
      display: flex;
      align-items: center;
      margin-top: 240px;
    }

    &__prime-logo {
      transform: translateY(10px);
      margin-left: 70px;
    }

    &__address {
      &.animate {
        transform: translateY(11px);
      }

      padding-left: 30px;
      margin-left: 30px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: black;
        height: 35px;
        width: 1px;
        left: 0;
        top: 0;
      }
    }

    &__right-wrap {
      grid-column: 5/8;
      padding-top: 120px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__contacts {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__email {
      width: var(--grid-column);
      white-space: nowrap;
    }

    &__phone {
      display: flex;
    }

    &__bottom-wrap {
      border-top: 1px solid black;
      padding-top: 46px;
    }

    &__copy {
      padding: 14px 0;
      min-width: var(--grid-column1);
    }

    &__policy {
      margin-left: 40px;
      padding: 14px 0;
    }

    &__social-large {
      grid-column: 5/6;
    }

    &__rclass {
      grid-column: 7/8;
    }

    &__field {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 55px;
      }
    }

    &__address-mob {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__form-policy-wrap {
      flex: 0 0 335px;
    }

    &__rclass {
      display: flex;
      justify-content: flex-end;
    }

    &__bottom-left-wrap {
      align-items: center;
    }

    &__prime-logo {
      svg {
        width: 147px;
        height: 27px;
      }
    }

    &__submit {
      margin-right: 45px;
      margin-bottom: 41px;
    }

    &__form-policy-wrap {
      padding-top: 4px;
    }

    &__form-bottom {
      display: flex;
      margin-top: 40px;
      flex-flow: wrap;
    }

    &__address_mob {
      display: none;
    }

    &__rclass {
      padding: 14px 0;
    }

    &__policy {
      padding: 14px 0;
    }
  }
}

@include respond('medium') {
  .footer {
    margin-top: 60px;
    padding-top: 100px;
    padding-bottom: 80px;

    &__bottom-left-wrap {
      grid-column: 1/5;
    }

    &__address-mob {
      grid-column: 1/6;
    }

    &__right-wrap,
    &__left-wrap {
      grid-column: 1/6;
    }

    &__right-wrap {
      margin-top: 70px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 45px;
      }
    }

    &__contacts {
      margin-top: 116px;
      padding-bottom: 30px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &__address-mob {
      margin-top: 30px;
    }

    &__prime-logo {
      //transform: translateY(10px);
    }

    &__address {
      &.animate {
        transform: translateY(1px);
      }

      padding-left: 20px;
      margin-left: 20px;
      position: relative;

      &:before {
        content: '';
        background: black;
        position: absolute;
        left: 0;
        top: -3px;
        width: 1px;
        height: 35px;

      }
    }

    &__social-mob {
      grid-column: 5/6;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 20px;
      //transform: translateY(7px);
    }

    &__prime-address {
      margin-left: calc(16px - var(--grid-gap));
      display: flex;
      grid-column: 2/5;
      align-items: center;
      padding-top: 18px;
      //transform: translateX(calc(0px - (var(--grid-gap) + 16px)));
    }

    &__bottom-wrap {
      padding-top: 74px;
      grid-column: 1/6;
    }

    &__policy {
      margin-left: 40px;
    }


    &__public-offer-text{
      grid-column: 1/4;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__address-large,
    &__social-large {
      display: none;
    }
  }
}

@include respond('small') {
  .footer {
    &__left-wrap {
      grid-column: 1/4;
    }
  }
}

@include respond-down('small') {
  .footer {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;


    &__bottom-left-wrap {
      grid-column: 1/4;
    }

    &__email {
      margin-top: 5px;
    }

    &__prime-logo {
      svg {
        width: 84px;
        height: 16px;
      }
    }

    &__address-mob {
      grid-column: 1/5;
    }

    &__right-wrap {
      grid-column: 1/5;
      margin-top: 40px;
    }

    &__field {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__form-bottom {
      margin-top: 20px;
    }

    &__form-policy-wrap {
      margin-top: 20px;
      max-width: 335px;
    }

    &__contacts {
      margin-top: 60px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
      border-bottom: 1px solid black;
    }

    &__kssk-logo {
      grid-column: 1/2;
      padding-bottom: 10px;

      svg {
        width: 35px;
        height: 39px;
      }
    }

    &__address-mob {
      margin-top: 16px;
    }

    &__prime-address {
      grid-column: 2/5;
      display: flex;
    }

    &__social-mob {
      grid-column: 1/3;
      margin-top: 40px;
    }

    &__address-wrap {
      grid-row-gap: 0;
    }

    &__address {
      display: none;
    }

    &__prime-address {
      align-items: center;
      margin-left: calc(0px - 2 * var(--grid-gap));
    }

    &__address_mob {
      margin-top: 4px;

      &.animate {
        transform: translateY(-2px);
      }

      margin-left: 14px;
      padding-left: 14px;
      position: relative;

      &:before {
        content: '';
        background: black;
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 22px;
      }
    }

    &__bottom-left-wrap {
      flex-direction: column;
    }

    &__bottom-wrap {
      grid-column: 1/5;
      margin-top: 50px;
      flex-direction: column;
    }

    &__copy {
      grid-column: 1/5;
    }

    &__rclass,
    &__policy {
      grid-column: 1/5;
      margin: 5px 0 -5px;
      padding: 5px 0;
      display: block;
    }


    &__public-offer-text{
      font-size: 12px;
      grid-column: 1/5;
    }
  }
}

@media (max-width: 374px) {
  .footer {
    &__left-wrap {
      grid-column: 1/5;
    }
  }
}