.text {
  &__main {
    @extend %text-main;
  }

  &__medium {
    @extend %text-medium;
  }

  &__mini {
    @extend %text-mini;
  }

  &__body {
    @extend %text-body;
  }

  &__body-mini {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 400;
    color: var(--primary-color);
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
    line-height: 140%;
  }

  &__error-page {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    color: var(--primary-color);
    font-variation-settings: var(--font-settings), 'opsz' 60;
    font-stretch: 151%;
    font-weight: 600;
    line-height: 120%;
  }
}

@include respond-up("medium") {
  .text {
    &__body-mini {
      --font-size: 18px;
    }

    &__error-page {
      --font-size: 200px;
    }
  }
}

@include respond-down("small") {
  .text {
    &__body-mini {
      --font-size: 12px;
    }

    &__error-page {
      --font-size: 80px;
    }
  }
}
