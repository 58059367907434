.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  justify-content: center;
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 40px;
    }
  }
}


@include respond-down('small') {
  .error-page {
    &__button {
      margin-top: 30px;
    }
  }
}