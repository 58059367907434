.similar-flats {
  &__title-wrap {
    overflow: hidden;

    &.animate {
      .similar-flats__title {
        transform: translateY(0);
      }
    }
  }

  &__link {
    padding: var(--grid-gap) var(--grid-gap) var(--grid-gap) 0;
    display: flex;
    flex-direction: column;

    .flats__layout {
      transition: transform .8s, opacity .8s;
      transition-timing-function: var(--teaser-transition-func);
      transform: translateY(100%);
      opacity: 0;

      &.animate {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__slider-item {
    position: relative;

    &.swiper-slide {

      &:not(:first-child) {
        .similar-flats__link {
          padding-left: var(--grid-gap);
          margin-left: calc(-1 * var(--grid-gap));
        }
      }
    }
  }

  &__fav {
    position: absolute;
    top: var(--grid-gap);
    right: var(--grid-gap);
  }

  &__slider-wrap {
    margin-right: calc(0px - var(--grid-spacer-and-indent));
    margin-left: calc(0px - var(--grid-spacer-and-indent));
  }

  &__slider-list {
    padding-left: var(--grid-spacer-and-indent);
    column-gap: var(--grid-gap);
  }

  &__slider {
    &.swiper {
      padding-right: calc(var(--grid-spacer-and-indent) * 2);
    }
  }

  &__price-info {
    display: flex;
    flex-direction: column;
  }

  &__price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-wrap {
    display: flex;
    justify-content: center;
  }

  &__slider-item {
    &.swiper-slide {
      border-right: 1px solid black;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: black;
        top: 0;
        left: 0;
        height: 1px;
        width: calc(100% + var(--grid-gap) + 1px);
      }

      &:after {
        position: absolute;
        content: '';
        background: black;
        bottom: 0;
        left: 0;
        height: 1px;
        width: calc(100% + var(--grid-gap) + 1px);
      }
    }

    &:last-child {
      &.swiper-slide {
        &:before,
        &:after {
          width: 100%;
        }
      }
    }
  }

  .flats__layout-inner {
    display: block;
    position: relative;
    width: max-content;

    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      transform-origin: left center;
      transition: transform .3s var(--default-transition-function);
      transform: scaleX(0);
      border-bottom: 1px solid var(--primary-color);
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        &::before {
          transition-delay: calc(#{$i} * .05s);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .similar-flats {
    margin-top: 200px;

    &__title-wrap {
      grid-column: 1/5;
    }

    &__slider-nav {
      grid-column: 7/8;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__slider-wrap {
      margin-top: 50px;
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column2);
      }
    }

    &__price-wrap {
      margin-top: 40px;
    }

    &__picture {
      height: calc(var(--grid-column) * 355 / 178);
    }

    &__link {
      .flats__details {
        margin-top: 15px;
      }

      &:hover {
        .flats {
          &__layout-inner {
            &:before {
              transform: scaleX(1);
            }
          }
        }

        .similar-flats {
          &__arrow-wrap {
            .slider-arrows_right-one {
              background: black;

              svg {
                path,
                line {
                  stroke: white;
                }
              }
            }
          }
        }
      }
    }

    .flats__layout {
      width: calc(100% - 55px);
    }
  }
}

@include respond('s-large') {
  .similar-flats {
    &__slider-nav {
      white-space: nowrap;
    }
  }
}

@include respond-up('medium') {
  .similar-flats {

  }
}

@include respond('medium') {
  .similar-flats {
    margin-top: 116px;

    &__title-wrap {
      grid-column: 1/5;
    }

    &__slider-nav {
      grid-column: 5/6;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
    }

    &__slider-wrap {
      margin-top: 27px;
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column3);
      }
    }

    &__price-wrap {
      margin-top: 20px;
    }

    &__picture {
      height: calc((var(--grid-column) + var(--grid-gap)) * 345 / 149);
    }

    &__link {
      .flats__details {
        margin-top: 0;
      }
    }
  }
}

@include respond-down('medium') {
  .similar-flats {

  }
}

@include respond-down('small') {
  .similar-flats {
    margin-top: 60px;

    &__title-wrap {
      grid-column: 1/5;
    }

    &__slider-nav {
      display: none;
    }

    &__slider-wrap {
      margin-top: 14px;
    }

    &__slider-item {
      &.swiper-slide {
        width: var(--grid-column3);
      }
    }

    &__price-wrap {
      margin-top: 22px;
    }

    &__picture {
      height: calc((var(--grid-column) + var(--grid-gap)) * 234 / 96);
    }

    &__link {
      .flats {
        &__details {
          margin-top: 0;
        }
      }
    }
  }
}