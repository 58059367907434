.interiors-slider {
  border-bottom: 1px solid black;

  &__picture {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--t);
      z-index: 400;
    }
  }

  &__slider-item {
    position: relative;

    //&:before,
    //&:after {
    //  content: '';
    //  position: absolute;
    //  background: white;
    //  top: 0;
    //  height: 100%;
    //  z-index: 100;
    //  width: 5px;
    //}
    //
    //&:before {
    //  left: -5px;
    //}
    //
    //&:after {
    //  right: -5px;
    //}
  }

  &__description-wrap {
    overflow: hidden;
    display: flex;
    background: white;
    opacity: 0;
    transition: opacity 1.2s var(--teaser-transition-func), transform 1.2s var(--teaser-transition-func);
    transition-delay: .2s;
  }

  &__description {
    transition: transform var(--default-timing) var(--teaser-transition-func);
  }

  &__text-slider-item {
    &.swiper-slide-prev {
      .interiors-slider__description-wrap {
        transform: translateY(-30px);
        opacity: 0;
      }
    }

    &.swiper-slide-active {
      .interiors-slider__description-wrap {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &.swiper-slide-next {
      .interiors-slider__description-wrap {
        transform: translateY(30px);
        opacity: 0;
      }
    }
  }

  &__slider-item {
    position: relative;
    //overflow: hidden;

    &.swiper-slide-prev {
      .interiors-slider__picture {
        transform: translateX(50%) scale(1);
      }
    }

    &.swiper-slide-active {
      &.interiors-slider {
        &__slider-item {
          z-index: 550;
          cursor: grab;
        }
      }

      .interiors-slider__picture {
        transform: translateX(0) scale(1);
      }
    }

    &.swiper-slide-next {
      .interiors-slider__picture {
        transform: translateX(-50%) scale(1);
      }
    }
  }

  &__slider-item {
    .interiors-slider {
      &__image-wrap {
        transform: scale(1.15);
        transition: transform 1.2s var(--teaser-transition-func);
      }
    }
  }

  &.animate {
    .interiors-slider {
      &__slider-item {
        .interiors-slider {
          &__image-wrap {
            transform: scale(1);
          }
        }
      }

      &__static-picture {
        transform: scale(1);
      }

      &__picture {
        //transform: scale(1);
        transition-delay: 1ms;
      }

      &__static-image-wrap,
      &__image-wrap {
        &:before {
          transform: translateY(-100%);
        }
      }
    }
  }

  &__static-image-wrap,
  &__image-wrap {
    position: relative;
    overflow: hidden;

    //&:before {
    //  content: '';
    //  background: white;
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 100%;
    //  height: 100%;
    //  transition: transform 1.2s var(--teaser-transition-func);
    //  z-index: 100;
    //}

    &:before {
      content: '';
      position: absolute;
      background: white;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 1.2s var(--teaser-transition-func);
      z-index: 100;
    }

    &.animate {
      &:before {
        transform: translateY(-100%);
      }
    }
  }


  &__image-wrap {
    padding-bottom: 55%;
  }

  &__static-picture {
    transform: scale(1.15);
  }

  &__static-picture,
  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: transform 1.2s var(--teaser-transition-func);
  }

  &__image,
  &__static-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {
    color: black;
  }

  &__slider-item {
    &.swiper-slide-active {
      .interiors-slider {
        &__description {
          color: black;
        }
      }
    }
  }

  &__slider-item {
    &.swiper-slide {
      .interiors-slider {
        &__image {
          transition: transform 2s var(--teaser-transition-func);
        }
      }
    }

    &.swiper-slide-next,
    &.swiper-slide-prev {
      .interiors-slider {
        &__image {
          transform: scale(1.15);
        }
      }
    }

    &.swiper-slide-active {
      .interiors-slider {
        &__image {
          transform: scale(1);
        }
      }
    }
  }

  &__text-slider-item {
    &.swiper-slide {
      transition: opacity var(--default-timing) var(--teaser-transition-func);
      opacity: 0;
    }

    &.swiper-slide-active {
      opacity: 1;
      //transform: scale(1);
    }
  }

  &__slider-block {
    position: relative;
  }
}

@include respond-up('s-large') {
  .interiors-slider {
    margin-top: 130px;
    padding-bottom: 40px;

    &__static-image-wrap {
      grid-column: 1/4;
      padding-bottom: 110%;
    }

    &__slider-block {
      padding-left: var(--grid-gap);
      grid-column: 4/8;

      &:before {
        height: calc(100% + 40px);
      }
    }

    &__description-wrap {
      display: flex;
      justify-content: flex-end;
      background: white;
      height: 228px;
      opacity: 0;
      transition: opacity 1.2s var(--teaser-transition-func), transform 1.2s var(--teaser-transition-func);
      transition-delay: .2s;
    }

    &__description {
      width: var(--grid-column2);
    }

    &__slider-nav {
      display: inline-block;
      position: absolute;
      top: calc((var(--grid-column4) - var(--grid-gap)) * 506 / 927 + 40px);
      z-index: 100;
    }

    &__text-slider-list {
      margin-top: 40px;
    }

    &__text-slider {
      height: 268px;
    }
  }
}

@include respond-up('medium') {
  .interiors-slider {

    &__slider-block {
      &:before {
        content: '';
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
      }
    }
  }
}

@include respond('medium') {
  .interiors-slider {
    margin-top: 70px;
    padding-bottom: 30px;

    &__static-image-wrap {
      grid-column: 1/3;
      height: calc(312 / 284 * var(--grid-column2));
    }

    &__slider-block {
      padding-left: var(--grid-gap);
      grid-column: 3/6;

      &:before {
        height: calc(100% + 30px);
      }
    }

    &__slider-nav {
      display: inline-block;
      position: absolute;
      top: calc((var(--grid-column3) - var(--grid-gap)) * 223 / 410 + 30px);
      z-index: 100;
    }

    &__description-wrap {
      height: 175px;
    }

    &__text-slider-list {
      padding-top: 115px;
    }

    &__text-slider {
      height: 290px;
    }
  }
}

@include respond-down('small') {
  .interiors-slider {
    margin-top: 30px;
    padding-bottom: 30px;

    &__text-slider {
      height: 240px;
    }

    &__wrap {
      grid-row-gap: 0;
    }

    &__static-image-wrap {
      grid-column: 1/5;
      padding-bottom: 63%;
    }

    &__slider-block {
      grid-column: 1/5;
      margin-top: 14px;
    }

    &__slider-nav {
      display: inline-block;
      position: absolute;
      top: calc(var(--grid-column4) * 182 / 334 + 14px);
      z-index: 100;
    }

    &__description-wrap {
      height: 163px;
    }

    &__text-slider-list {
      padding-top: 77px;
    }
  }
}