.mortgage {
  border-top: 1px solid black;

  &.animate {
    .mortgage {
      &__title-wrap,
      &__from,
      &__number {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__title-wrap {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;

  }

  &__percent{
    overflow: hidden;
  }

  &__from,
  &__number {
    transform: translateY(120%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__wrap {
    grid-row-gap: 0;
    overflow: hidden;
  }

  &__percent {
    display: flex;
    align-items: baseline;
  }
}

@include respond-up('s-large') {
  .mortgage {
    padding-top: 80px;

    &__title {
      grid-column: 1/5;
    }

    &__button {
      margin-top: 40px;
    }

    &__percent {
      grid-column: 6/8;
    }

    &__number {
      margin-left: 30px;
    }
  }
}

@include respond-up('medium') {
  .mortgage {
    &__button-mob {
      display: none;
    }
  }
}

@include respond('medium') {
  .mortgage {
    padding-top: 50px;

    &__title {
      grid-column: 1/4;
    }

    &__button {
      margin-top: 30px;
    }

    &__percent {
      grid-column: 4/6;
    }

    &__number {
      margin-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .mortgage {

  }
}

@include respond-down('small') {
  .mortgage {
    padding-top: 30px;

    &__button {
      display: none;
    }

    &__title {
      grid-column: 1/5;
    }

    &__percent {
      grid-column: 1/3;
      margin-top: 10px;
    }

    &__number {
      margin-left: 10px;
    }

    &__button-mob-wrap {
      grid-column: 1/5;
      margin-top: 20px;
    }
  }
}