.realty-plan {
  &__sort-text-wrap {
    color: var(--primary-color-opacity-40);
    display: flex;
  }

  &__text-mini {
    color: var(--primary-color-opacity-40);
  }

  &__realty-plan-wrapper {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
}

@include respond-up("s-large") {
  .realty-plan {
    margin-top: 40px;

    &__top-wrap {
      &_tab {
        display: none;
      }

      display: flex;
      justify-content: space-between;
    }

    &__sort-text-wrap {
      &_mob {
        display: none;
      }
    }

    &__sort-wrap {
      cursor: pointer;
    }

    &__plan {
      margin-top: 40px;
    }

    &__text-mini {
      margin-top: 100px;
      margin-bottom: 60px;
    }

    &__text-mini,
    &__tabs-wrapper {
      grid-column: 1/3;
    }

    &__realty-plan-wrapper {
      grid-column: 3/8;
    }

    &__plan-wrapper {
      margin-top: 40px;
      border-top: 1px solid black;
    }

    &__tabs-wrapper {
      padding-top: 40px;
    }

    &__tabs {
      background: var(--background-color);
      padding: 40px 40px 40px 0;
    }

    &__tabs-heading {
      padding-left: 40px;
    }

    &__tabs-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-up("medium") {
  .realty-plan {
    &__flats-found {
      color: var(--primary-color-opacity-40);
    }

    &__sort-wrap {
      margin-left: 20px;
    }
  }
}

@include respond("medium") {
  .realty-plan {
    &__top-wrap {
      &_large {
        display: none;
      }

      display: flex;
      justify-content: space-between;
      grid-column: 1/6;
      margin-top: 40px;
    }

    &__text-mini {
      margin-top: 80px;
      margin-bottom: 40px;
      grid-column: 1/4;
    }

    &__realty-plan-wrapper {
      margin-top: 20px;
    }

    &__plan-wrapper {
      margin-top: 30px;
    }

    &__tabs-wrapper,
    &__realty-plan-wrapper {
      grid-column: 1/6;
    }
  }
}

@include respond-down("medium") {
  .realty-plan {
    .genplan {
      margin: 0 calc(0px - var(--grid-spacer));

      &__layers {
        // Не надо двигать слои
        //padding: 0 var(--grid-spacer);
      }
    }

    &__tabs-heading {
      display: none;
    }

    &__plan-wrapper {
      grid-row-gap: 0;
    }

    &__sort-text-wrap {
      &_large {
        display: none;
      }
    }
  }
}

@include respond-down("small") {
  .realty-plan {
    &__flats-found {
      display: none;
    }

    &__text-mini {
      margin-top: 70px;
      margin-bottom: 30px;
      grid-column: 1/6;
    }

    &__realty-plan-wrapper {
      margin-top: 20px;
    }

    &__sort-text-wrap {
      &_mob {
        margin-top: 30px;
        width: var(--grid-column4);
        flex-wrap: wrap;
        gap: 14px;
        row-gap: 0;
      }
    }

    &__plan-wrapper {
      margin-top: 20px;
    }

    &__tabs-wrapper,
    &__realty-plan-wrapper {
      grid-column: 1/5;
    }
  }
}
