.project-map {
  &__picture,
  &__main-icon {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    padding: 3px;
    border: 1px solid black;
    background: white;
  }

  &__cluster-icon {
    font-family: var(--font);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: black;
    padding: 3px;
    color: white;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    font-stretch: 151%;
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;

    position: relative;
    z-index: 150;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 50px;
    }

    &:before {
      border: 1px solid black;
      z-index: 140;
    }

    &:after {
      border: 4px solid white;
      z-index: 130;
    }
  }

  &__main-icon-img,
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 31px;
  }

  &__object-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 54px;
  }

  &__icon-img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  &__project-hint {
    display: none;
  }

  .ymaps-2-1-79-image-with-content-content {
    width: auto !important;
    height: auto !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

@include respond-up('s-large') {
  .project-map {
    margin-top: 50px;
  }
}

@include respond-up('medium') {
  .project-map {
    &__picture,
    &__main-icon {
      height: 69px;
      width: 69px;
    }

    &__cluster-icon {
      width: 46px;
      height: 46px;
      font-size: 25px;

      &:before {
        height: 54px;
        width: 54px;
      }

      &:after {
        height: 46px;
        width: 46px;
      }
    }
  }
}

@include respond('medium') {
  .project-map {
    margin-top: 30px;
  }
}

@include respond-down('small') {
  .project-map {
    margin-top: 14px;

    &__picture,
    &__main-icon {
      height: 50px;
      width: 50px;
    }

    &__cluster-icon {
      width: 39px;
      height: 39px;
      font-size: 20px;

      &:before {
        height: 45px;
        width: 45px;
      }

      &:after {
        height: 38px;
        width: 38px;
      }
    }
  }
}
