.filter-fieldset {
  &__field {
    &_rooms {
      .filter-field {
        &__values {
          display: flex;
        }

        &__value {
          input {
            display: none;
          }

          input[type="checkbox"] {
            &:checked + label {
              background: black;
              color: white;
            }
          }

          input[type="checkbox"] {
            + label {
              border-radius: 100px;
              border: 1px solid black;
              color: black;
              background: var(--background-color);
              transition: var(--default-transition);
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    &_price,
    &_year,
    &_floor,
    &_areaTotal {
      .filter-field {
        &__values {
          display: flex;
          justify-content: space-between;
        }

        &__input {
          background: none;
          border: none;
          margin: 0;
          padding: 0;

          &:nth-child(2) {
            text-align: end;
          }
        }
      }
    }
  }

  .filter-field {
    &__wrapper {
      .slider-target {
        display: flex;
        justify-content: center;

        .slider-base {
          border-radius: 0;
          background: var(--primary-color-opacity-40);
          height: 2px;
          width: calc(100% - 25px);

          &:before {
            content: '';
            position: absolute;
            background-color: var(--primary-color-opacity-40);
            top: 0;
            left: -13px;
            height: 2px;
            width: 13px;
          }

          &:after {
            content: '';
            position: absolute;
            background-color: var(--primary-color-opacity-40);
            top: 0;
            right: -12px;
            height: 2px;
            width: 12px;
          }

          .slider-connects {
            border-radius: 0;

            .slider-connect {
              background: black;
            }
          }

          .slider-origin {
            .slider-handle {
              box-shadow: none;
              border: 1px solid black;
              width: 25px;
              height: 25px;
              top: -11px;
              right: calc(var(--slider-handle-width, 16px) / 2 * -1.5);
              transition: var(--default-transition);

              .slider-tooltip {
                display: none;
              }
            }
          }
        }
      }
    }

    &__select {
      background-image: url("../../templates/_parts/icons/arrow-down.svg");
      background-repeat: no-repeat;
      background-position-x: calc(100% - 13px);
      background-position-y: center;
      appearance: none;
      border: none;
      cursor: pointer;
      transition: var(--default-transition);
      border-radius: 100px;
      padding: 3px 35px 3px 13px;
      margin: -3px -35px -3px -13px;
    }
  }
}

@include respond-up('large') {
  .filter-fieldset {
    &__field {
      &_price,
      &_year,
      &_floor,
      &_areaTotal {
        .filter-field {
          &__input {
            width: calc(var(--grid-column) - 40px);
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .filter-fieldset {
    &__field {
      &:not(:first-child) {
        margin-top: 52px;
      }

      &_rooms {
        .filter-field {
          &__values {
            flex-wrap: wrap;
            margin: -5px;

            > * {
              margin: 5px;
            }
          }

          &__label {
            cursor: pointer;
          }
        }
      }

      &_price,
      &_year,
      &_floor,
      &_areaTotal {
        .filter-field {
          &__wrapper {
            margin-top: 20px;
          }
        }
      }
    }

    .filter-field {
      &__wrapper {
        .slider-target {
          .slider-base {
            .slider-origin {
              .slider-handle {
                &:hover {
                  background: black;
                }
              }
            }
          }
        }
      }

      &__select {
        &:hover {
          background-color: var(--background-color);
        }
      }

      &__value {
        input[type="checkbox"] {
          + label {
            &:hover {
              background: black;
              color: white;
            }
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .filter-fieldset {
    &__field {
      &_price,
      &_year,
      &_floor,
      &_areaTotal {
        .filter-field {
          &__input {
            width: calc(var(--grid-column) - 26px);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .filter-fieldset {
    &__field {
      &_rooms {
        .filter-field {
          &__value {
            input[type="checkbox"] {
              + label {
                padding: 15px 20px;
              }
            }
          }
        }
      }
    }

    .filter-field {
      &__wrapper {
        .slider-target {
          margin-top: 16px;
          height: 2px;

          .slider-base {
            .slider-origin {
              .slider-handle {
                width: 25px;
                height: 25px;
                top: -11px;
              }
            }
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .filter-fieldset {
    &__field {
      &:not(:first-child) {
        margin-top: 50px;
      }

      &:nth-child(2) {
        margin-top: 60px;
      }

      &_rooms {
        .filter-field {
          &__value {
            &:not(:first-child) {
              input[type="checkbox"] {
                + label {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }

      &_price,
      &_year,
      &_floor,
      &_areaTotal {
        .filter-field {
          display: flex;
          align-items: center;

          &__label {
            flex: 0 0 calc(var(--grid-column2) - 40px);
          }

          &__wrapper {
            margin-left: var(--grid-gap);
            width: 100%;
          }

          &__input {
            width: calc(var(--grid-column) + var(--grid-gap));
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .filter-fieldset {
    &__field {
      &_rooms {
        .filter-field {
          &__values {
            flex-wrap: wrap;
            margin: -3px;

            > * {
              margin: 3px;
            }
          }

          &__value {
            input[type="checkbox"] {
              + label {
                padding: 12px 16px;
              }
            }
          }
        }
      }

      &:not(:first-child) {
        margin-top: 30px;
      }

      &_price,
      &_year,
      &_floor,
      &_areaTotal {
        .filter-field {
          &__values {
            margin-top: 14px;
          }

          .slider-target {
            margin-top: 14px;
            height: 2px;
          }

          &__input {
            width: calc(var(--grid-column2) - 20px);
          }
        }
      }
    }
  }
}