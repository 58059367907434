.fav{
  display: flex;
  align-items: center;

  &[data-in-fav="true"] {
    .fav{
      &__icon{
        svg path {
          fill: var(--primary-color);
          transition: 0.3s;
        }
      }
    }
  }
  &__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid black;
    width: 55px;
    height: 55px;

    svg {
      transition: width .3s;
      path {
        fill: var(--t);
        stroke: var(--primary-color);
        transition: fill 0.3s;
      }
    }
  }
}

@include respond-up('s-large') {
  .fav{
    &[data-in-fav="true"] {
      .fav{
        &__icon{
          svg {
            width: 25px;
            height: auto;
          }
        }
      }
    }

    &:hover {
      .fav {
        &__icon {
          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .fav{

    &__icon {
      width: 55px;
      height: 55px;
    }

    &__text {
      margin-left: 20px;
    }
  }
}

@include respond('small') {
  .fav{


    &__icon {
      width: 43px;
      height: 43px;
    }

    &__text {
      margin-left: 15px;
    }
  }
}