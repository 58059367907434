.balance-cards {
  --parallax-speed: 60px;

  &__image-wrapper {
    position: relative;
    display: flex;
  }

  &__picture {
    display: flex;
  }

  &__item {
    position: relative;
  }

  .animate {
    .balance-cards {
      &__item {
        .balance-cards__title {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  @for $i from 1 through 5 {
    .balance-cards {
      &__item {
        &:nth-child(#{$i}) {
          z-index: calc(1200 + #{$i} * 100);
        }
      }
    }
  }

  &__main-text {
    position: relative;

    &.animate {
      .balance-cards {
        &__main-text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__main-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }
  }

  &__image-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image-wrap {
    height: 100%;
    display: flex;
    overflow: hidden;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  width: 100%;
    //  height: 100%;
    //  top: 0;
    //  left: 0;
    //  background: rgba(0, 0, 0, 0.4);
    //}
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item {
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;

    &:first-child {
      background: var(--background-color);
    }

    &:nth-child(2) {
      .balance-cards {
        &__title,
        &__description {
          color: white;
          z-index: 1000;
        }
      }
    }

    &:nth-child(3) {
      border: 1px solid black;
      background: white;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}

@include respond-up('large') {
  .balance-cards {
    &__item {
      height: calc(var(--grid-column3) * 486 / 715);
    }

    &__picture {
      height: calc(var(--grid-column3) * 486 / 715 + var(--parallax-speed));
    }
  }
}

@include respond-up('s-large') {
  .balance-cards {
    padding-top: 200px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &__list {
      position: relative;
    }

    &__item {
      min-height: calc(var(--grid-column3) * 486 / 715);
      padding: 40px;

      &:first-child {
        grid-column: 1/4;
      }

      &:nth-child(2) {
        width: var(--grid-column3);
        top: 110px;
        left: calc(var(--grid-column2) + var(--grid-gap));
        position: absolute;
      }

      &:nth-child(3) {
        width: var(--grid-column3);
        top: 220px;
        right: 0;
        position: absolute;
      }
    }

    &__title,
    &__description {
      width: calc(var(--grid-column2) - var(--grid-gap));
    }

    &__lines {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 67px;
      background-image: url("../../templates/_parts/icons/lines.svg");
    }
  }
}

@include respond('s-large') {
  .balance-cards {
    &__description {
      padding-top: 90px;
    }

    &__picture {
      height: calc(var(--grid-column3) * 486 / 715 * 1.3);
    }
  }
}

@include respond('medium') {
  .balance-cards {
    margin-top: 120px;

    &__image-wrapper {
      width: var(--grid-column4);
    }

    &__picture {
      //min-height: calc(var(--grid-column4) * 369 / 597 + var(--parallax-speed));
      width: 100%;
    }

    &__list {
      position: relative;
    }

    &__description {
      padding-top: 70px;
    }

    &__list {
      grid-row-gap: 0;
    }

    &__item {
      padding: 30px 30px 0 30px;

      &:first-child {
        grid-column: 1/5;
        //min-height: calc(var(--grid-column4) * 369 / 597);
        padding-bottom: 110px;
      }

      &:nth-child(2) {
        grid-column: 1/6;
        width: var(--grid-column4);
        display: flex;
        justify-self: center;
        //min-height: calc(var(--grid-column4) * 341 / 597);
        padding-bottom: 110px;
        transform: translate3d(calc(0px - var(--grid-gap) * 3), -40px, 0);
      }

      &:nth-child(3) {
        grid-column: 2/6;
        //min-height: calc(var(--grid-column4) * 383 / 597);
        padding-bottom: 152px;
        transform: translateY(-80px);
      }
    }

    &__title,
    &__description {
      width: calc(var(--grid-column3) - var(--grid-gap));
    }

    &__lines {
      height: 81px;
    }
  }
}

@include respond-down('medium') {
  .balance-cards {
    &__lines {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url("../../templates/_parts/icons/lines-mob.svg");
    }
  }
}

@include respond-down('small') {
  .balance-cards {
    margin-top: 60px;

    &__title,
    &__description {
      width: var(--grid-column3);
    }

    &__list {
      grid-row-gap: 0;
    }

    &__image-wrapper {
      width: calc(var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2));
    }

    &__picture {
      //min-height: calc((var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2)) * 341 / 597 + var(--parallax-speed));
      //width: 100%;
      width: calc(var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2));
    }

    &__list {
      position: relative;
    }

    &__description {
      padding-top: 50px;
    }

    &__item {
      padding: 20px 20px 60px 20px;
      width: calc(var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2));
      grid-column: 1/5;
      display: flex;

      &:first-child {
        //min-height: calc((var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2)) * 284 / 294);
      }

      &:nth-child(2) {
        //min-height: calc((var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2)) * 284 / 294);
        justify-self: center;
        margin-top: -20px;
      }

      &:nth-child(3) {
        //min-height: calc((var(--grid-column3) + var(--grid-gap) + (var(--grid-column) / 2)) * 305 / 294);
        justify-self: end;
        margin-top: -20px;
      }
    }

    &__lines {
      height: 40px;
    }
  }
}