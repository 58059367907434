%text-mini {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  color: var(--primary-color);
  font-stretch: 110%;
  font-variation-settings: var(--font-settings);
  line-height: 140%;
}

@include respond-up("medium") {
  %text-mini {
    --font-size: 14px;
  }
}

@include respond-down("small") {
  %text-mini {
    --font-size: 12px;
  }
}