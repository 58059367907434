.fav-success {
  background: var(--background-color);
  padding-left: var(--grid-spacer);
  padding-right: var(--grid-spacer);
  transition: transform .4s var(--teaser-transition-func);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  transform: translateY(100%);

  &._opened {
    transform: translateY(0);
  }

  &__wrap,
  &__closer-wrap {
    display: flex;
    align-self: center;
  }

  &__text-wrap {
    display: inline;
    align-self: center;
  }

  &__link-text {
    position: relative;
    display: inline-block;
    padding-bottom: 3px;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform-origin: right;
    }

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      background-color: #000;
      bottom: 0;
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &__closer {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      background: #858585;
      left: 0;
      top: 50%;
      transform-origin: center;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

@include respond-up('s-large') {
  .fav-success {
    display: flex;
    justify-content: flex-end;

    &__wrap {
      flex: 0 0 var(--grid-column6);
      justify-content: space-between;
    }

    &__closer-wrap {
      margin-left: 36px;
    }

    &__link-wrap {
      &.underline {
        .fav-success {
          &__link-text {
            &::after {
              transition: transform 0.4s var(--teaser-transition-func) 0.4s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform 0.4s var(--teaser-transition-func);
              transform: scaleX(0);
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .fav-success {
    padding-top: 23px;
    padding-bottom: 23px;

    &__content-wrap {
      display: flex;
    }

    &__icon-wrap {
      width: 82px;
      height: 82px;
      display: flex;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__text-wrap {
      margin-left: 36px;
    }

    &__closer {
      width: 29px;
      height: 29px;

      &:before,
      &:after {
        width: 37px;
        height: 3px;
      }
    }
  }
}

@include respond('medium') {
  .fav-success {

  }
}

@include respond-down('medium') {
  .fav-success {

  }
}

@include respond-down('small') {
  .fav-success {
    padding-top: 20px;
    padding-bottom: 20px;

    &__icon-wrap {
      display: none;
    }

    &__closer-wrap {
      margin-left: 19px;
    }

    &__closer {
      width: 24px;
      height: 24px;

      &:before,
      &:after {
        width: 30px;
        height: 2px;
      }
    }
  }
}