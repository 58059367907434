.popup-modal-form {
  &__main {
    flex: 0 0 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }

  form {
    flex: 0 0 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('medium') {
  .popup-modal-form {
    .policy-checkbox {
      &__inner {
        padding-top: 3px;
      }
    }

    &__button {
      margin-top: 30px;
    }

    form {
      padding-right: 70px;
    }
  }
}

@include respond-down('small') {
  .popup-modal-form {
    .policy-checkbox {
      &__inner {
        padding-top: 5px;
      }
    }

    &__button {
      margin-top: 20px;
    }

    &__bottom {
      margin-top: 70px;
    }

    &__wrap,
    &__info,
    &__main {
      height: 100%;
    }

    &__main {
      justify-content: space-between;
    }
  }
}