.interiors {
  &.animate {
    .interiors__heading-text-inner {
      transform: translateY(0);
      opacity: 1;
    }
    .interiors__text {
      &:before {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__heading-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }

    #text-wrap-line {
      position: relative;
      display: inline-block;
      color: var(--t);

      &::before {
        content: '';
        position: absolute;
        background: black;
        height: 2px;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &__text {
    position: relative;
  }

  &__main-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 2 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: calc(2.6s + (#{$i} * 0.1s));
      }
    }
  }
}

@include respond-up('s-large') {
  .interiors {
    padding-top: 200px;

    &__title {
      grid-column: 1/2;
    }

    &__text {
      grid-column: 3/8;
    }

    &__heading-text-inner {
      #text-wrap-line {
        width: 76px;
        margin-right: 33px;

        &::before {
          width: 76px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .interiors {
    &__title {
      grid-column: 1/2;
    }
  }
}

@include respond('medium') {
  .interiors {
    padding-top: 120px;

    &__wrap {
      grid-row-gap: 0;
    }

    &__text {
      grid-column: 1/6;
      margin-top: 40px;
    }

    &__heading-text-inner {
      #text-wrap-line {
        width: 46px;
        margin-right: 21px;

        &::before {
          width: 46px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .interiors {
    &__text {
      &:before {
        height: 2px;
      }
    }
  }
}

@include respond-down('small') {
  .interiors {
    padding-top: 60px;

    &__title {
      grid-column: 1/3;
    }

    &__wrap {
      grid-row-gap: 0;
    }

    &__text {
      grid-column: 1/5;
      margin-top: 20px;
    }

    &__heading-text-inner {
      #text-wrap-line {
        width: 26px;
        margin-right: 12px;

        &::before {
          width: 26px;
        }
      }
    }
  }
}