.header {
  position: fixed;
  width: 100%;
  z-index: 4700;
  background: white;
  transition: opacity 0.6s var(--teaser-transition-func) 1.3s;

  &._not-first-visit {
    transition-delay: 0s;
  }

  &.need-animation {
    opacity: 0;
  }

  &.animate-out {
    opacity: 1;
  }

  &__container {
    position: relative;
  }

  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: height .2s var(--default-transition-function);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__menu-block {
    display: flex;
    height: 100%;
  }

  &__contacts-wrap {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__phone {
    display: flex;
    align-items: center;
  }

  &__fav,
  &__catalog-button,
  &__consultation-button-wrap {
    margin-left: var(--header-margin);
  }
}

@include respond-up('large') {
  .header {
    --header-margin: 70px;
  }
}

@include respond-up('s-large') {
  .header {

    &__wrap {
      height: 100px;
    }

    &._sticky {
      .header__wrap {
        height: 80px;
      }

      .drop-menu {
        top: 80px;
      }
    }

    &__catalog-text-wrap {
      margin-left: 20px;
    }

    &__square-wrap {
      width: 15px;
      height: 15px;
      position: relative;
    }

    &__square {
      position: absolute;
      display: block;
      width: 5px;
      height: 5px;
      background: black;
      transition: var(--default-transition);

      &_first {
        top: 0;
        left: 0;
      }

      &_second {
        top: 0;
        right: 0;
      }

      &_third {
        bottom: 0;
        left: 0;
      }

      &_fourth {
        bottom: 0;
        right: 0;
      }
    }

    &__catalog-button {
      display: flex;
      align-items: center;

      &:hover {
        .header {
          &__square {

            &_first {
              transform: translate3d(1px, 1px, 0);
            }

            &_second {
              transform: translate3d(-1px, 1px, 0);
            }

            &_third {
              transform: translate3d(1px, -1px, 0);
            }

            &_fourth {
              transform: translate3d(-1px, -1px, 0);
            }
          }

          &__catalog-text {
            transform: translateY(23px);

            &_clone {
              top: -46px;
            }
          }
        }
      }
    }

    &__catalog-text-wrap {
      overflow: hidden;
      display: flex;
      transform: translateY(1px);
    }

    &__catalog-text {
      position: relative;
      transition: var(--default-transition);

      &_clone {
        position: absolute;
        left: 0;
        top: -25px;
      }
    }

    &__consultation-button-wrap {
      display: flex;
      align-items: center;

      &.underline {
        .header {
          &__consultation-button-text {
            &::after {
              transition: transform 0.4s var(--teaser-transition-func) 0.4s;
              transform: scaleX(1);
            }

            &::before {
              transition: transform 0.4s var(--teaser-transition-func);
              transform: scaleX(0);
            }
          }
        }
      }
    }

    &__consultation-button-text {
      position: relative;
      display: block;
      padding-bottom: 13px;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        background-color: #000;
        bottom: 0;
        transform-origin: right;
        //transition: transform 0.4s ease-out;
      }

      &:after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        background-color: #000;
        bottom: 0;
        transform: scaleX(0);
        transform-origin: left;
        //transition: transform 0.4s ease-out;
      }
    }
  }
}

@include respond('s-large') {
  .header {
    --header-margin: clamp(45px, calc(45 / 1200 * 100vw), 70px);
  }
}

@include respond-up('medium') {
  .header {
    &__phone-icon {
      display: none;
    }
  }
}

@include respond('medium') {
  .header {
    &__wrap {
      height: 80px;
    }

    &._sticky {
      .header__wrap {
        height: 64px;
      }

      .drop-menu {
        height: calc(var(--vh, 1vh) * 100 - 62px);
        transform: translateY(-37px);
      }
    }

    &__catalog-button,
    &__consultation-button-wrap {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .header {
    &__fav {
      &.fav {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    &__wrap {
      height: 54px;
    }

    &._sticky {
      .header__wrap {
        height: 44px;
      }

      .drop-menu {
        height: calc(var(--vh, 1vh) * 100 - 43px);
        transform: translateY(-56px);
      }
    }

    &__catalog-button,
    &__consultation-button-wrap,
    &__menu-text,
    &__phone-number {
      display: none;
    }

    &__logo {
      svg {
        width: 101px;
        height: auto;
      }
    }
  }
}