.flat {
  border-top: 1px solid black;

  &__share-wrap {
    position: absolute;
    left: 0;
    top: 0;
  }

  &.animate {
    .flat__price {
      transform: translateY(0);
    }
  }

  &__price-wrapper {
    overflow: hidden;
    width: 100%;
  }

  &__price {
    text-transform: none;
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__mortgage-info-block {
    font-size: 12px;
    padding: 12px 16px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    position: absolute;
    transition: var(--default-transition);
    top: 100%;
    left: 0;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
  }

  &__mortgage-wrap {
    position: relative;

    &:hover {
      .flat {
        &__mortgage-info-block {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }
      }
    }
  }

  &__flat-wrap {
    position: relative;
  }

  &__tabs {
    display: flex;
    justify-content: center;
  }

  &__price-wrap {
    background: var(--background-color);
  }

  &__about-price {
    color: var(--primary-color-opacity-40);
  }

  &__mortgage-text {
    color: var(--primary-color-opacity-40);
  }

  &__mortgage-price {
    margin-left: 5px;
  }

  &__mortgage-info {
    margin-left: 12px;
    display: flex;
  }

  &__images-container {
    display: flex;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__price-top-wrap {
    display: flex;
  }
}

@include respond-up('s-large') {
  .flat {
    margin-top: 50px;
    padding-top: 40px;

    &__info-block {
      grid-column: 5/8;
      padding-bottom: 30px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: black;
        bottom: 0;
        left: calc(0px - var(--grid-gap));
        height: 1px;
        width: calc(100% + var(--grid-gap));
      }
    }

    &__price-wrap {
      padding: 40px;
    }

    &__item-title {
      flex: 0 0 calc(var(--grid-column) + var(--grid-gap));
    }

    &__item-value {
      margin-left: calc(var(--grid-gap) * 2);
    }

    &__flat-wrap {
      grid-column: 1/5;
      border-bottom: 1px solid #000;
      padding-bottom: 30px;

      &:before {
        position: absolute;
        content: '';
        background: black;
        top: -40px;
        right: 0;
        height: calc(100% + 40px);
        width: 1px;
      }
    }

    &__buttons-wrap {
      margin-top: 40px;
      &_without-price{
        margin: unset;
      }
    }

    &__buttons-wrap {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }

    &__button {
      &:nth-child(2) {
        width: fit-content;
      }
    }

    &__mortgage-wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 20px 20px 20px 0;
      margin: 0 -20px -20px 0;
    }


    &__image-link {
      width: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));
      height: calc(var(--grid-column3) * 0.8 + 2 * var(--grid-gap));
    }

    &__tabs {
      margin-top: 40px;
    }

    &__price-top-wrap {
      flex-direction: column;
    }
  }
}

@include respond-up('medium') {
  .flat {
    &__item {
      padding-bottom: 20px;
      padding-top: 20px;
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px dashed black;
      }
    }

    &__list {
      margin-top: 30px;
    }

    &__about-price {
      margin-top: 30px;
    }

    &__mortgage-info-block {
      width: 85%;
    }
  }
}

@include respond('medium') {
  .flat {
    margin-top: 30px;
    padding-top: 30px;

    &__flat-wrap {
      grid-column: 1/6;
    }

    &__image-link {
      height: calc((var(--grid-column2) + var(--grid-gap)) * 393 / 308);
    }

    &__tabs {
      margin-top: 65px;
    }

    &__images-container {
      margin-top: 100px;
    }

    &__info-block {
      grid-column: 1/6;
      margin-top: 50px;
    }

    &__price-wrap {
      padding: 30px;
    }

    &__item-title {
      flex: 0 0 var(--grid-column2);
    }

    &__item-value {
      margin-left: var(--grid-gap);
    }

    &__buttons-wrap {
      margin-top: 30px;
      &_without-price{
        margin: unset;
      }
    }

    &__buttons-wrap {
      display: flex;
    }

    &__button {
      &:nth-child(2) {
        width: fit-content;
        margin-left: 16px;
      }
    }

    &__mortgage-wrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 20px 0 20px 30px;
      margin: -20px 0 -20px 0;
      width: 100%;
    }

    &__price-top-wrap {
      align-items: center;
    }
  }
}

@include respond-down('medium') {
  .flat {
    grid-row-gap: 0;
  }
}

@include respond-down('small') {
  .flat {
    margin-top: 14px;
    padding-top: 14px;

    &__flat-wrap {
      grid-column: 1/5;
    }

    &__image-link {
      height: calc((var(--grid-column2) + var(--grid-gap) * 2) * 244 / 191);
    }

    &__tabs {
      margin-top: 40px;
    }

    &__images-container {
      margin-top: 40px;
    }

    &__info-block {
      grid-column: 1/5;
      margin-top: 30px;
    }

    &__price-wrap {
      padding: 20px;
    }

    &__item-title {
      flex: 0 0 var(--grid-column2);
    }

    &__item-value {
      margin-left: var(--grid-gap);
    }

    &__buttons-wrap {
      margin-top: 20px;
      &_without-price{
        margin: unset;
      }
    }

    &__buttons-wrap {
      display: flex;
      flex-direction: column;
    }

    &__button {
      &:nth-child(2) {
        margin-top: 14px;
      }
    }

    &__mortgage-wrap {
      display: flex;
      cursor: pointer;
      padding: 10px 0 10px 0;
      margin: 0 0 -10px 0;
      width: 100%;
    }

    &__item {
      padding-bottom: 13px;
      display: flex;

      &:not(:first-child) {
        margin-top: 13px;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed black;
      }
    }

    &__list {
      margin-top: 30px;
    }

    &__about-price {
      margin-top: 20px;
    }

    &__price-top-wrap {
      flex-direction: column;
    }

    &__mortgage-info-block {
      width: 100%;
    }

    &__share-wrap {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      right: 0;
    }
  }
}