.flats-modal {
  &__picture {
    display: flex;
  }

  &__text-wrap {
    flex: 1 0 auto;
  }

  &__price-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    border-bottom: 1px solid black;
    border-left: 1px solid black;

    &:first-child {
      border-top: 1px solid black;
    }
  }

  &__link {
    display: flex;
    padding-top: var(--grid-gap);
    padding-bottom: var(--grid-gap);
  }

  &__list {
    width: inherit;
    height: fit-content;
  }

  &__layout {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
  }

  &__item {
    &.animate {
      .flats-modal__layout {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__mortgage {
    color: var(--primary-color-opacity-40);
  }

  &__price-info {
    display: flex;
    flex-direction: column;
  }

  &__price{
    text-transform: none;
  }
}

@include respond-up('s-large') {
  .flats-modal {
    &__layout {
      position: relative;
      width: fit-content;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 1px;
        background-color: black;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--teaser-transition-func);
      }

      &:hover {
        &:before {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .4s var(--teaser-transition-func);
        }
      }
    }

    &__image-wrap {
      padding: 0 calc(var(--grid-gap) / 2);
    }

    &__details,
    &__price-wrap {
      margin-top: 30px;
    }
  }
}

@include respond('s-large') {
  .flats-modal {
  }
}

@include respond-up("medium") {
  .flats-modal {
    &__details {
      display: flex;
      flex-direction: column;
    }

    &__detail {
      display: flex;
      padding-bottom: 10px;

      &:not(:last-child) {
        border-bottom: 1px dashed black;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &__detail-name {
      flex: 0 0 var(--grid-column);
    }

    &__mortgage {
      margin-top: 5px;
    }

    &__layout_mob {
      display: none;
    }
  }
}

//@include respond-up("medium") {
@include respond("medium") {
  .flats-modal {
    &__link {
      display: flex;
      padding: 40px 0;
    }

    &__details {
      margin-top: 30px;
    }

    &__detail-description {
      margin-left: calc(var(--grid-gap) * 3);
    }

    &__text-wrap {
      display: flex;
      flex-direction: column;
      margin-left: var(--grid-gap);
    }

    &__image-wrap {
      flex: 1 0 var(--grid-column1);
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    &__price-wrap {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

@include respond-down("medium") {
  .flats-modal {
  }
}

@include respond-down("small") {
  .flats-modal {
    &__detail_area,
    &__detail-name_renovation,
    &__layout_large {
      display: none;
    }

    &__price-wrap {
      margin-top: 22px;
    }

    &__mortgage {
      margin-top: 3px;
    }

    &__link {
      display: flex;
      flex-direction: column;
      padding: 20px 0 20px 20px;
    }

    &__image-wrap {
      width: var(--grid-column4);
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }

    &__text-wrap {
      margin-top: 35px;
    }

    &__detail-description_renovation {
      position: relative;
      padding-left: 16px;
      margin-left: 16px;

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 19px;
        left: 0;
        top: -2px;
        background: var(--primary-color-opacity-40);
      }
    }
  }
}
