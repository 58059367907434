.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);

  &__main {
    z-index: 100;
  }
}

@include respond-up('s-large') {
  .layout {
    --gen-plan-height: calc(var(--grid-column7) * 807 / 1720);

    &__main {
      padding-top: 100px;
    }
  }
}

@include respond('medium') {
  .layout {
    --gen-plan-height: calc(var(--grid-column5) * 578 / 754);

    &__main {
      padding-top: 80px;
    }
  }
}

@include respond-down('small') {
  .layout {
    --gen-plan-height: calc(var(--grid-column4) * 334 / 382);

    &__main {
      padding-top: 54px;
    }
  }
}