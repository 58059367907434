.hamburger {
  &__menu-lines {
    position: relative;
    display: block;

    &:before, &:after {
      content: '';
      background: black;
      position: absolute;
      width: 100%;
      left: 0;
      transition: transform var(--default-timing) var(--teaser-transition-func);
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  &__menu-button {
    display: flex;
    align-items: center;
  }

  &__wrap {
    position: relative;
    display: flex;
    height: 12px;
    width: 48px;
  }
}

@include respond-up('large') {
  .hamburger {
    &:before {
      left: calc(0px - var(--grid-gap));;
    }
  }
}

@include respond-up('s-large') {
  .hamburger {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 13px;
      height: 86px;
      width: 193px;
      border: 1px solid black;
      border-bottom-color: white;
      transition: opacity .4s var(--teaser-transition-func);
      opacity: 0;
    }

    &._open {
      &:before {
        opacity: 1;
      }
    }

    &__menu-lines {
      width: 46px;
      height: 16px;
      transform-origin: center center;
    }

    &__menu-button {
      display: flex;
      align-items: center;

      &:hover {
        .hamburger {
          &__menu-lines {
            &:before {
              transform: translateY(3px);
            }

            &:after {
              transform: translateY(-3px);
            }
          }
        }
      }
    }

    &._open {
      &:hover {
        .hamburger {
          &__menu-lines {
            &:before {
              transform: rotate(30deg) translate3d(0px, 8px, 0);
            }

            &:after {
              transform: rotate(-30deg) translate3d(0px, -8px, 0);
            }
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .hamburger {
    &:before {
      left: calc(0px - var(--grid-gap) / 2);;
    }
  }
}

@include respond-up('medium') {
  .hamburger {
    &__menu-lines {
      width: 46px;
      height: 17px;

      &:before, &:after {
        height: 3px;
      }
    }

    &__menu-text {
      margin-left: 20px;
    }

    &._open {
      .hamburger {
        &__menu-lines {
          &:before {
            width: 37px;
            transform: rotate(45deg) translate3d(2px, 8px, 0);
          }

          &:after {
            width: 37px;
            transform: rotate(-45deg) translate3d(2px, -8px, 0);
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .hamburger {
    &__menu-lines {
      width: 37px;
      height: 11px;

      &:before, &:after {
        height: 2px;
      }
    }

    &._open {
      .hamburger {
        &__menu-lines {
          &:before, &:after {
            width: 30px;
          }

          &:before {
            transform: rotate(45deg) translate3d(6px, 0px, 0);
          }

          &:after {
            transform: rotate(-45deg) translate3d(6px, 0px, 0);
          }
        }

        &__line {
          &_top {
            width: 21px;
          }

          &_bottom {
            width: 21px;
          }
        }
      }
    }

    &__menu-text {
      display: none;
    }

    &__wrap {
      width: 34px;
    }
  }
}