@media print {
  .print-flat {
    &_has-plan {
      .print-flat {
        &__details {
          border-top: none;
          padding-top: 0;
          margin-bottom: 12.3mm;
        }
      }
    }

    &__picture {
      display: flex;
      align-self: center;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__trim-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 4.8mm 0;
      align-items: center;
      position: fixed;
      top: 0;
      width: calc(100% - 14.2mm);
    }

    &__header-logo {
      width: 25.8mm;
      height: 7.2mm;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__contacts {
      display: flex;
      align-items: center;
    }

    &__contact-icon {
      width: 4.2mm;
      height: 4.2mm;
      margin-left: 9mm;
      display: flex;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    &__contact {
      margin-left: 2.3mm;
      font-family: var(--font);
      font-style: normal;
      font-weight: 500;
      font-size: 8pt;
      line-height: 110%;
      text-transform: uppercase;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
    }

    &__block {
      margin-top: 25mm;
      margin-bottom: -25mm;

      &:not(:nth-child(2)) {
        padding-top: 25mm;
      }

      .print-flat {

      }
    }

    &__flat-name {
      font-family: var(--font);
      font-style: normal;
      font-weight: 600;
      font-size: 22.5pt;
      line-height: 120%;
      text-transform: uppercase;
      color: black;
      font-stretch: 151%;
      font-variation-settings: var(--font-settings);
      padding-bottom: 5.3mm;
      width: 100%;
      padding-top: 0.2mm;
    }

    &__planning-name {
      position: absolute;
      top: 0;
    }

    &__planning {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      width: 100%;

      &_main {
        .print-flat {
          &__picture {

            width: 170.7mm;
            height: 131.9mm;
          }

          &__image {
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &_plan {
        .print-flat {
          &__picture {
            width: 100%;
            height: 99mm;
          }
        }
      }
    }

    &__plannings {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0.2mm;
      border-top: 0.2mm solid black;

      &_single {
      }

      &_double {
      }
    }

    &__planning-name {
      padding-top: 5.3mm;
    }

    &__details {
      margin-top: 5.3mm;
      padding-top: 12.3mm;
      border-top: 0.2mm solid black;
      display: block;
    }

    &__prices {
      display: flex;
      align-items: center;
    }

    &__mortgage_price {
      margin-left: 5.6mm;
    }

    &__item {
      padding-bottom: 3.4mm;
      display: flex;
      border-bottom: 0.2mm solid black;
      margin-top: 3.4mm;
    }

    &__list {
      border-top: 0.2mm solid black;
      margin-top: 5.3mm;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5.5mm;
    }

    &__item-title {
      flex: 0 0 50%;
    }

    &__item-value {
      margin-left: 5.5mm;
    }

    &__trim-list {
      //display: grid;
      //grid-template-columns: 1fr 1fr;
      //grid-column-gap: 5.5mm;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      border-top: 0.2mm solid black;
      border-bottom: 0.2mm solid black;
      padding-bottom: 30.3mm;
      margin-top: 5.3mm;
    }

    &__trim-item {
      flex: 0 0 calc(50% - 2.25mm);
      padding-top: 5.3mm;
      display: flex;
      flex-direction: column;
    }

    &__trim-picture {
      display: flex;
      margin-top: 3.5mm;
      height: 60.9mm;
      width: 95mm;
      overflow: hidden;
    }

    &__small-block {
      //margin-top: 50mm;
      padding-top: 25mm;
      margin-bottom: -25mm;

      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
      }

      //&:nth-last-child(1) {
      //  margin-top: 0;
      //}

      //&:nth-last-child(1) {
      //  margin-bottom: 0;
      //}
    }
  }

  .print-apartments-list {
    &__items {
      margin-top: 5.3mm;
    }

    &__item {
      background: var(--background-color);
      margin-top: 0.7mm;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 5.3mm;
      padding: 4.2mm 0;

      &:first-child {
        margin: 0;
      }
    }

    &__item-part {
      display: flex;
      align-items: center;
    }

    &__item-part_floor {
      grid-column: span 2;
      padding-left: 5.3mm;
    }

    &__item-part_price {
      justify-self: flex-end;
      padding-right: 5.3mm;
    }

    &__floor-value {
      flex: 0 0 18.9mm;
    }
  }
}

