.infrastructure {
  &__item {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        .infrastructure {
          &__place {
            transition-delay: calc(#{$i} * 0.1s);
          }
        }
      }
    }
  }

  &__list {
    &.animate-out {
      .infrastructure {
        &__place {
          transition-delay: 0s;
        }
      }
    }
  }

  &__place {
    transition: transform .8s, opacity .8s, color .4s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;
  }

  &__list {
    &.animate {
      .infrastructure {
        &__place {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &__title-wrapper {
    overflow: hidden;

    &.animate {
      .infrastructure__title {
        transform: translateY(0);
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__image-wrap {
    border-radius: 50%;
    overflow: hidden;
  }

  &__item {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background: black;
      width: 100%;
      height: 1px;
      top: 0;
      left: 0;
      //transition: background var(--default-timing) var(--teaser-transition-func);
    }

    &:after {
      content: '';
      position: absolute;
      background: black;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      //transition: background var(--default-timing) var(--teaser-transition-func);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__position,
  &__place {
    color: black;
  }

  &__time {
    color: var(--primary-color-opacity-40);
  }

  //body
  //body
  //mini
  &__time {
    line-height: 140%;
  }

  &__place,
  &__time {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 400;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  &__picture {
    display: flex;
    overflow: hidden;
    pointer-events: none;
    z-index: 5;
    transition: opacity .8s;
    border-radius: 50%;
  }
}

@include respond-up("x-large") {
  .infrastructure {
  }
}

@include respond-up("s-large") {
  .infrastructure {
    padding-top: 335px;

    &__picture {
      position: fixed;
      height: calc(var(--grid-column) * 212 / 212);
      width: calc(var(--grid-column) * 212 / 212);
      opacity: 0;
      z-index: 110;
    }

    &__image {
      transform: scale(1.4);
      transition: transform 1.4s cubic-bezier(0.16, 1, 0.3, 1);
    }

    &__time {
      color: black;
    }

    &__item {
      &._shadow {
        &:before,
        &:after {
          background: #999;
        }

        .infrastructure {
          &__position,
          &__place,
          &__time {
            color: var(--primary-color-opacity-40);
          }
        }
      }

      &._hovered {
        &:before,
        &:after {
          background: black;
          z-index: 100;
        }
      }
    }

    &__position,
    &__time {
      transition: color var(--default-timing) var(--teaser-transition-func);
    }

    &__list-wrap {
      margin-top: 100px;
    }

    &__item {
      padding: 33px 0 32px;

      &:before,
      &:after {
        transition: background var(--default-timing) var(--teaser-transition-func);
      }
    }

    &__position {
      grid-column: 1/2;
    }

    &__text-wrap {
      grid-column: 4/8;
      display: flex;
      justify-content: space-between;
    }

    &__place {
      width: var(--grid-column3);
      --font-size: 30px;
    }

    &__time {
      width: var(--grid-column);
      display: flex;
      align-items: center;
      --font-size: 18px;
    }
  }
}

@include respond("medium") {
  .infrastructure {
    &__place {
      line-height: 110%;
    }
  }
}

@include respond("medium") {
  .infrastructure {
    padding-top: 120px;

    &__list {
      margin-top: 70px;
    }

    &__item {
      padding: 11px 0;
    }

    &__text-wrap {
      display: flex;
      align-items: center;
      grid-column: 2/6;
    }

    &__place {
      flex: 0 0 var(--grid-column2);
      --font-size: 25px;
    }

    &__time {
      margin-left: var(--grid-gap);
      --font-size: 18px;
    }

    &__picture {
      height: calc((var(--grid-column) - var(--grid-gap) * 1.5) * 78 / 78);
      width: calc((var(--grid-column) - var(--grid-gap) * 1.5) * 78 / 78);
    }
  }
}

@include respond-down("medium") {
  .infrastructure {
    &__position {
      display: none;
    }
  }
}

@include respond-down("small") {
  .infrastructure {
    padding-top: 60px;

    &__place {
      line-height: 140%;
    }

    &__place {
      --font-size: 16px;
    }

    &__time {
      --font-size: 12px;
    }

    &__list {
      margin-top: 30px;
    }

    &__item {
      padding: 9px 0;
    }

    &__text-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      grid-column: 2/5;
    }

    &__picture {
      height: 52px;
      width: 52px;
      //height: calc((var(--grid-column) - var(--grid-gap) * 1.5) * 52 / 52);
      //width: calc((var(--grid-column) - var(--grid-gap) * 1.5) * 52 / 52);
    }
  }
}