.preloader {
  position: fixed;
  z-index: 4850;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 440px;
  pointer-events: none;

  &__wrapper {
    position: fixed;
    z-index: 4800;
    width: 100vw;
    height: 100vh;
    background: white;
    transition: opacity .5s .8s var(--teaser-transition-func), transform .1s 7s ease-in-out;
    //transition-delay: 0.8s;

    &.animate-out {
      opacity: 0;
      transform: translateY(100%);
      pointer-events: none;
      //z-index: 0;
    }
  }

  &__title {
    svg {
      height: 100%;
      width: 100%;

      path {
        transition: transform .5s var(--teaser-transition-func);
        -webkit-transition: transform .5s var(--teaser-transition-func);
        //animation: preloader-out .5s var(--teaser-transition-func) forwards;
        //-webkit-animation: preloader-out .5s var(--teaser-transition-func) forwards;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: calc(#{$i} * 0.1s);
            -webkit-transition-delay: calc(#{$i} * 0.1s);
          }

          //&:nth-last-child(#{$i}) {
          //animation-delay: calc(1.9s + (#{$i} * 0.1s));
          //-webkit-animation-delay: calc(1.9s + (#{$i} * 0.1s));
          //}
        }
      }
    }
  }

  &__subtitle {
    margin-top: 16px;
    overflow: hidden;

    svg {
      height: 100%;
      width: 100%;
      transition: transform .5s .5s;
      -webkit-transition: transform .5s .5s;
      animation: preloader-out .5s 1.9s var(--teaser-transition-func) forwards;
      -webkit-animation: preloader-out .5s 1.9s var(--teaser-transition-func) forwards;
    }
  }
}

@keyframes preloader-out {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, -100%, 0);
  }
}