.projects-link {
  display: flex;
  align-items: center;
  transition: opacity .5s var(--teaser-transition-func);

  &__text {
    margin-left: 12px;
  }
}

@include respond-up('s-large') {
  .projects-link {
    &:hover {
      .projects-link {
        &__arrow {
          transform: translateX(-10px);
        }
      }
    }

    &__arrow {
      transition: var(--default-transition);
    }
  }
}

@include respond-up('medium') {
  .projects-link {

  }
}

@include respond('medium') {
  .projects-link {
    margin-top: 60px;
  }
}

@include respond-down('medium') {
  .projects-link {

  }
}

@include respond-down('small') {
  .projects-link {
    margin-top: 20px;
  }
}