.content_heading {
  &__content-header {
    &.animate {
      .content_heading__wrapper {
        .content_heading__title-wrap {
          .content_heading__title {
            transform: translateY(0);
          }
        }

        .content_heading__tabs {
          &.tabs__first {
            .tabs__list {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }
    }

    &.need-animation {
      .projects-link {
        opacity: 0;
      }
    }
  }

  &__tabs {
    &.tabs__first {
      .tabs__list {
        transform: translateY(100%);
        transition: transform .8s, opacity .8s;
        transition-timing-function: var(--teaser-transition-func);
        opacity: 0;

      }
    }
  }

  &__title-wrap {
    overflow: hidden;

    &.animate {
      .content_heading__title {
        transform: translateY(0);
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__spectators {
    display: flex;
    align-items: center;
    color: var(--primary-color-opacity-40);
  }

  &__spectators-text {
    margin-left: 10px;
  }

  &__wrapper {
    position: relative;
  }

  &__spectators {
    position: absolute;
    right: 0;
  }

  &__projects-link {
    display: flex;
    align-items: center;
  }
}

@include respond-up("s-large") {
  .content_heading {
    &__projects-link {
      padding: 20px;
      margin: -20px;
    }

    &__content-header {
      margin-top: 80px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    &__tabs {
      margin-left: 70px;
    }

    &__spectators {
      align-self: flex-end;
      bottom: 0;

      &_mob {
        display: none;
      }
    }
  }
}

@include respond("medium") {
  .content_heading {
    &__spectators {
      bottom: -90px;
    }

    &__tabs {
      margin-top: 30px;
    }

    &__title-wrap {
      margin-top: 20px;
    }
  }
}

@include respond-down("medium") {
  .content_heading {
    &__spectators {
      &_large {
        display: none;
      }
    }
  }
}

@include respond-down("small") {
  .content_heading {
    &__tabs {
      margin-top: 14px;
    }

    &__spectators {
      position: relative;
      margin-top: 10px;
    }

    &__title-wrap {
      margin-top: 14px;
    }
  }
}