.flats {
  &__image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  &__filter-wrapper {
    background: var(--background-color);
  }

  &__drop-filter {
    display: flex;
    align-items: flex-end;
  }

  &__drop-filter-text {
    color: var(--primary-color-opacity-40);
  }

  &__layout {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;
    //width: fit-content;
  }

  &__item {
    position: relative;

    &.animate {
      .flats__layout {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &__fav {
    position: absolute;
    right: 0;
  }

  &__mortgage {
    color: var(--primary-color-opacity-40);
  }

  &__price-info {
    display: flex;
    flex-direction: column;
  }

  &__price {
    text-transform: none;
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .flats {
    &__details-wrap {
      width: var(--grid-column2);
    }

    &__price-wrap {
      width: var(--grid-column);
    }
  }
}

@include respond-up("s-large") {
  .flats {
    &__filter-wrapper {
      position: sticky;
      top: 100px;
    }

    &__fav {
      top: 60px;
    }

    &__drop-filter-text {
      transition: color var(--default-timing) var(--teaser-transition-func);
    }

    &__drop-filter {
      align-items: center;
      padding: 20px 0;
      margin: -20px 0;

      svg {
        path {
          transition: fill-opacity var(--default-timing) var(--teaser-transition-func);
        }
      }

      &:hover {
        svg {
          path {
            fill-opacity: 1;
          }
        }

        .flats__drop-filter-text {
          color: black;
        }
      }
    }

    &__item {
      &:not(:first-child) {
        border-top: 1px solid black;
      }

      &:first-child,
      &:nth-child(2) {
        .flats__layout {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    &__filter-buttons-wrap {
      margin-top: 40px;
    }

    &__drop-filter {
      justify-content: center;
    }

    &__filter-button,
    &__drop-filter-text_tab {
      display: none;
    }

    &__layout {
      position: relative;
      width: fit-content;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 1px;
        background-color: black;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--teaser-transition-func);
      }
    }

    &__link {
      display: flex;
      justify-content: space-between;

      &:hover {
        .flats {
          &__layout {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .4s var(--teaser-transition-func);
            }
          }

          &__arrow-wrap {
            .slider-arrows_right-one {
              background: black;

              svg {
                path,
                line {
                  stroke: white;
                }
              }
            }
          }
        }
      }
    }

    &__details-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__details {
      margin-top: 40px;
    }

    &__detail-description {
      margin-left: var(--grid-gap);
    }

    &__price-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: var(--grid-gap);
    }

    &__price-info {
      align-items: flex-end;
    }

    &__arrow-wrap {
      margin-top: 75px;
    }

    &__text-wrap {
      display: flex;
      padding: 60px 0;
      margin-left: var(--grid-gap);
    }

    &__image-wrap {
      width: calc(var(--grid-column) + 3 * var(--grid-gap));
      display: flex;
      justify-content: flex-end;
      align-content: center;
    }
  }
}

@include respond('s-large') {
  .flats {
    &__details-wrap {
      width: calc(var(--grid-column2) + var(--grid-gap) - 40px);
    }

    &__price-wrap {
      width: calc(var(--grid-column) + var(--grid-gap));
    }
  }
}

@include respond-up("medium") {
  .flats {
    &__drop-filter-text {
      margin-left: 22px;
    }

    &__filter-wrapper {
      padding: 40px;
    }

    &__details {
      display: flex;
      flex-direction: column;
    }

    &__detail {
      display: flex;
      padding-bottom: 10px;

      &:not(:last-child) {
        border-bottom: 1px dashed black;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &__detail-name {
      flex: 0 0 var(--grid-column);
    }

    &__mortgage {
      margin-top: 5px;
    }

    &__layout_mob {
      display: none;
    }
  }
}

@include respond("medium") {
  .flats {
    &__drop-filter-text_full {
      display: none;
    }

    &__fav {
      top: 40px;
    }

    &__filter-buttons-wrap {
      display: flex;
      margin-top: 50px;
      align-items: center;
    }

    &__drop-filter {
      flex: 0 0 calc(var(--grid-column2) - 40px);
      padding: 15px 0;
      margin: -15px 0;
    }

    &__filter-button {
      margin-left: var(--grid-gap);
      width: 100%;
    }

    &__link {
      display: flex;
    }

    &__details {
      margin-top: 30px;
    }

    &__detail-description {
      margin-left: calc(var(--grid-gap) * 3);
    }

    &__text-wrap {
      display: flex;
      flex-direction: column;
      margin-left: var(--grid-gap);
      justify-content: center;
      padding: 40px 0;
    }

    &__image-wrap {
      width: var(--grid-column2);
      display: flex;
      justify-content: center;
    }

    &__details-wrap,
    &__price-wrap {
      width: var(--grid-column3);
    }

    &__price-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down("medium") {
  .flats {
    &__price-wrap {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      border-top: 1px solid black;
    }
  }
}

@include respond-down("small") {
  .flats {
    &__layout {
      width: calc(100% - 43px);
    }

    &__drop-filter-text_tab {
      display: none;
    }

    &__fav {
      top: 20px;
    }

    &__filter-wrapper {
      padding: 20px 20px 30px;
    }

    &__filter-buttons-wrap {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 40px;
      align-items: center;
    }

    &__drop-filter {
      margin-top: 15px;
      padding: 15px 0;
    }

    &__drop-filter-text {
      margin-left: 12px;
    }

    &__filter-button {
      width: 100%;
    }

    &__detail_area,
    &__detail-name_renovation,
    &__layout_large {
      display: none;
    }

    &__price-wrap {
      margin-top: 22px;
    }

    &__mortgage {
      margin-top: 3px;
    }

    &__link {
      display: flex;
      flex-direction: column;
      padding: 20px 0 20px 20px;
    }

    &__image-wrap {
      width: var(--grid-column4);
      display: flex;
      justify-content: center;
      //margin-top: 35px;
      margin-left: -20px;
    }

    //&__text-wrap {
    //margin-top: 35px;
    //}

    &__detail-description_renovation {
      position: relative;
      padding-left: 16px;
      margin-left: 16px;

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 19px;
        left: 0;
        top: -2px;
        background: var(--primary-color-opacity-40);
      }
    }
  }
}
