.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
  border-radius: 100px;
  display: inline-block;
  white-space: nowrap;

  &_black,
  &_black-grey{
    background: #000000;
    color: #fff;
  }

  &_white {
    background: white;
    color: black;
  }

  &_grey {
    background: var(--background-color);
    color: black;
  }
}

@include respond-up('s-large') {
  .button {

    &_black {
      &:hover {
        background: white;
        color: black;
      }
    }

    &_white {
      &:hover {
        background: black;
        color: white;
      }
    }

    &_black-grey {
      &:hover {
        background: var(--background-color);
        color: black;
      }
    }

    &_grey {
      &:hover {
        background: black;
        color: white;
      }
    }
  }
}

@include respond-up('medium') {
  .button {
    padding: 15px 20px;
  }
}

@include respond-down('small') {
  .button {
    padding: 12px 18px;
  }
}