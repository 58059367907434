.drop-menu {
  position: absolute;
  top: 100px;
  opacity: 0;
  pointer-events: none;
  background-color: white;
  transition: opacity var(--default-timing) var(--teaser-transition-func);
  z-index: 4500;

  &__item {
    overflow: hidden;
  }

  &._delay {
    .drop-menu {
      &__item {
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            .drop-menu {
              &__link {
                transition-delay: calc(#{$i} * 0.1s);
              }
            }
          }
        }
      }
    }
  }

  &__link {
    transform: translateY(100%);
    opacity: 0;
    transition: transform var(--default-timing), color var(--default-timing), opacity var(--default-timing);
    transition-timing-function: var(--teaser-transition-func);
  }

  &._open {
    opacity: 1;
    pointer-events: auto;
    .drop-menu {
      &__link {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &._close {
    transition-delay: .2s;
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    color: #fff;
    font-weight: 300;
    line-height: 130%;
  }

  &__link {
    display: block;
  }
}

@include respond-up('large') {
  .drop-menu {
    left: calc(0px - var(--grid-gap));
  }
}

@include respond-up('s-large') {
  .drop-menu {
    border: 1px solid black;

    &__fav-wrap {
      display: none;
    }

    &__item {
      &._hovered {
        .drop-menu {
          &__link {
            color: black;
          }
        }
      }

      &._shadow {
        .drop-menu {
          &__link {
            color: var(--primary-color-opacity-40);
          }
        }
      }
    }

    &__projects-link {
      padding-top: 42px;
      padding-bottom: 35px;
      padding-left: 35px;
    }

    &__wrap {
      padding-top: 36px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        left: 0;
        top: -1px;
        width: 193px;
        background: white;
      }
    }

    &__link {
      position: relative;
      padding: 8px 120px 8px 35px;
      color: black;
    }

    &__bottom-mob,
    &__button-mob {
      display: none;
    }

    &._open {
      transform: translateY(0px);
    }
  }
}

@include respond('s-large') {
  .drop-menu {
    left: calc(0px - var(--grid-gap) / 2);
  }
}

@include respond-up('medium') {
  .drop-menu {
  }
}

@include respond('medium') {
  .drop-menu {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - 78px);
    margin-left: calc(0px - var(--grid-spacer));
    padding: 60px var(--grid-spacer) 40px var(--grid-spacer);
    transform: translateY(-21px);

    &__fav-wrap {
      margin-top: 60px;
    }

    &__list {
      margin-top: 60px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    &__kssk-wrap {
      grid-column: 1/3;
    }

    &__social-wrap {
      grid-column: 5/6;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    &__projects-link-mob {
      margin-top: 30px;
    }

    &__button-mob {
      width: 100%;
    }

    &__bottom-mob {
      margin-top: 20px;
    }

    &__wrap {
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    &__projects-link {
      &.projects-link {
        display: none;
      }
    }

    &__link {
      color: black;
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - 53px);
    margin-left: calc(0px - var(--grid-spacer));
    padding: 30px var(--grid-spacer) 20px var(--grid-spacer);
    transform: translateY(-46px);

    &__fav-wrap {
      margin-top: 30px;
    }

    &__list {
      margin-top: 40px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &__kssk-wrap {
      grid-column: 1/4;
    }

    &__social-wrap {
      grid-column: 4/5;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    &__projects-link-mob {
      margin-top: 20px;
    }

    &__button-mob {
      width: 100%;
    }

    &__bottom-mob {
      margin-top: 20px;
    }

    &__wrap {
      height: 100%;
    }
  }
}