h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-stretch: 151%;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3, {
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 600;
  line-height: 120%;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  line-height: 110%;
}

@include respond-up("s-large") {
  h1, .h1 {
    --h-size: clamp(0px, calc((120 / 1920) * 100vw), 120px);
  }

  h2, .h2 {
    --h-size: clamp(0px, calc((60 / 1920) * 100vw), 60px);
  }

  h3, .h3 {
    --h-size: clamp(0px, calc((40 / 1920) * 100vw), 40px);
  }

  h4, .h4 {
    --h-size: 25px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: calc((60 / 834) * 100vw);
  }

  h2, .h2 {
    --h-size: calc((40 / 834) * 100vw);
  }

  h3, .h3 {
    --h-size: calc((35 / 834) * 100vw);
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 29px;
  }

  h2, .h2 {
    --h-size: 22px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 14px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}

@media (max-width: 374px) {
  h1, .h1 {
    --h-size: calc((29 / 375) * 100vw);
  }
}