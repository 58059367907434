.popup-modal-resolve {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__subtitle {
    margin-top: 20px;
  }

  &__title {
    font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
    font-weight: 500;
    line-height: 110%;
    font-family: var(--h-font);
    font-size: var(--h-size);
    text-transform: uppercase;
    font-stretch: 151%;
    font-style: normal;
  }

  &__timer-minutes,
  &__timer-seconds {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 51px;
  }

  &__timer-seconds {
    margin-left: 4px;
  }

  &__timer {
    background: var(--background-color);
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &__timer-time {
    display: flex;
    margin-left: 20px;
  }

  &__timer-text {
    color: var(--primary-color-opacity-40);
  }
}

@include respond-up("s-large") {
  .popup-modal-resolve {
    &__title {
      --h-size: 25px;
    }
  }
}

@include respond-up("medium") {
  .popup-modal-resolve {
    &__button {
      margin-top: 30px;
    }

    &__timer {
      padding: 8px 8px 8px 20px;
    }
  }
}

@include respond("medium") {
  .popup-modal-resolve {
    &__title {
      --h-size: 22px;
    }
  }
}

@include respond-down("small") {
  .popup-modal-resolve {
    &__button {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 20px;
    }

    &__title {
      --h-size: 18px;
    }

    &__timer {
      margin-top: 30px;
      padding: 8px 8px 8px 10px;
    }
  }
}