.flats-index {
  &__title-wrapper {
    overflow: hidden;

    &.animate {
      .flats-index__title {
        transform: translateY(0);
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__button-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__item-link {
    display: flex;
    align-items: center;
  }

  &__arrow-wrap {
    margin-left: var(--grid-gap);
    display: flex;
    justify-content: flex-end;
  }

  &__list-wrap {
    border-bottom: 1px solid black;
    grid-row-gap: 0;
  }

  &__text-wrap {
    display: flex;
  }

  &__image-wrap {
    align-items: center;
    justify-content: center;
  }

  &__image-wrap,
  &__image-wrap-mob {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-container {
    z-index: 50;
  }

  &__item-wrap {
    z-index: 100;
  }

  &__picture {
    display: flex;
  }
}

@include respond-up("s-large") {
  .flats-index {
    margin-top: 180px;

    &__picture {
      //height: calc((var(--grid-column) + var(--grid-gap)) * 333/258);
      height: auto;
      width: calc(var(--grid-column2) / 1.1);
    }

    &__table-wrap {
      margin-top: 50px;
    }

    &__item-link {
      padding: 40px 0 40px var(--grid-gap);
      margin-left: calc(0px - var(--grid-gap));
      border-top: 1px solid black;
      border-left: 1px solid black;

      &:hover {
        .flats-index {
          &__layout {
            &:before {
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .4s var(--teaser-transition-func);
            }
          }

          &__arrow-wrap {
            .slider-arrows_right-one {
              background: black;

              svg {
                path,
                line {
                  stroke: white;
                }
              }
            }
          }
        }
      }
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column4);
    }

    &__layout-wrap {
      flex: 0 0 var(--grid-column2);
    }

    &__layout {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: black;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--teaser-transition-func);
      }
    }

    &__found {
      flex: 0 0 var(--grid-column2);
    }

    &__arrow-wrap {
      flex: 0 0 var(--grid-column);
    }

    &__item {
      grid-column: 1/8;
      display: flex;

      &:first-child {
        .flats-index {
          &__image-wrap {
            opacity: 1;
            transform: scale(1) translateY(-50%);
          }
        }
      }

      &._hovered {
        .flats-index {
          &__image-wrap {
            opacity: 1;
            transform: scale(1) translateY(-50%);
            z-index: 100;
          }
        }
      }

      &._shadow {
        .flats-index {
          &__image-wrap {
            opacity: 0;
            transform: scale(1.1) translateY(-50%);
          }
        }
      }
    }

    &__item-wrap {
      margin-left: var(--grid-gap);
      width: var(--grid-column5);
    }

    &__image-container {
      flex: 0 0 var(--grid-column2);
      display: flex;
      justify-content: center;
    }

    &__image-wrap {
      position: absolute;
      transition: var(--default-transition);
      opacity: 0;
      transform: scale(1.1) translateY(-50%);
      top: 50%;
    }

    &__list-wrap {
      position: relative;
    }

    &__image-wrap-mob {
      display: none;
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-up("medium") {
  .flats-index {
    &__text-wrap {
      align-items: center;
    }

    &__found {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond("medium") {
  .flats-index {
    margin-top: 120px;

    &__picture {
      //height: calc((var(--grid-column) / 2 - 12px) * 66 / 51);
      height: auto;
      width: calc(var(--grid-column1));
    }

    &__item {
      grid-column: 1/6;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column3);
    }

    &__table-wrap {
      margin-top: 30px;
    }

    &__item-link {
      padding: 30px 0;
    }

    &__layout-wrap {
      flex: 0 0 var(--grid-column2);
    }

    &__found,
    &__arrow-wrap,
    &__image-wrap-mob {
      flex: 0 0 var(--grid-column);
    }

    &__image-wrap-mob {
      margin: -30px 0;
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down("medium") {
  .flats-index {
    &__image-wrap {
      display: none;
    }

    &__text-wrap {
      margin-left: var(--grid-gap);
    }

    &__item {
      border-left: 1px solid black;
      border-top: 1px solid black;
    }

    &__image-wrap-mob {
      justify-content: center;
    }
  }
}

@include respond-down("small") {
  .flats-index {
    margin-top: 60px;

    &__picture {
      //height: calc((var(--grid-column) / 2) * 49 / 37);
      height: auto;
      width: calc(var(--grid-column1));
    }

    &__table-wrap {
      margin-top: 14px;
    }

    &__item-link {
      padding: 20px 0;
    }

    &__text-wrap {
      flex: 0 0 var(--grid-column2);
      flex-direction: column;
    }

    &__found {
      margin-top: 2px;
      color: var(--primary-color-opacity-40);
    }

    &__arrow-wrap,
    &__image-wrap-mob {
      flex: 0 0 var(--grid-column);
    }

    &__item {
      grid-column: 1/5;
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__image-wrap-mob {
      margin: -20px 0;
    }
  }
}
