:root {
  --modal-bg: rgba(0, 0, 0, 0.3);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-closer-color: white;
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);

    &._popup {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .modal {
        &__content {
          overflow: hidden;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */

          &::-webkit-scrollbar {
            display: none;
          }
        }

        &__closer {
          &:before,
          &:after {
            background: black;
          }
        }
      }
    }

    &._opened {
      background: var(--modal-bg);

      &._popup {
        background: var(--t);
      }
    }

    &._closed {
      background: var(--t);
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    align-items: center;
    background: var(--modal-content-background);

    &._opened {
    }

    &._closed {
    }

    &._popup {
      border: 1px solid black;
      box-shadow: 0px 15px 50px 0px #00000040;
    }
  }

  &__content {
    overflow: scroll;
    display: flex;

    img {
      max-width: 100%;
    }
  }

  &__closer {
    position: absolute;
    color: #000000;
    text-decoration: none;
    cursor: pointer;

    &:before {
      content: '';
      height: 2px;
      background: #fff;
      position: absolute;
      top: 0;
      transform-origin: top left;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: 0;
      transform-origin: bottom left;
      transform: rotate(-45deg);
    }
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .modal {
    &__container {
      width: calc(50vw + var(--grid-gap) / 2);
      height: calc(var(--vh, 1vh) * 100);
    }

    &__content {
      padding: var(--grid-gap);
      width: calc(50vw + var(--grid-gap) / 2);
      height: calc(var(--vh, 1vh) * 100);
    }

    &__layout {
      justify-content: flex-end;
      transform: translateX(100%);

      &._opened {
        transform: translateX(0);
      }

      &._closed {
        transform: translateX(100%);
      }
    }

    &__closer {
      top: calc(var(--vh, 1vh) * 50);
      transform: translateY(-50%);
      left: calc(-83px - var(--grid-gap));
    }
  }
}

@include respond('s-large') {
  .modal {
  }
}

@include respond-up('medium') {
  .modal {
    &__bg {
      &._popup {
        width: 800px;
        height: 370px;
        top: unset;
        bottom: 40px;
        left: unset;
        right: 40px;

        .modal {
          &__closer {
            transform: translateY(0);
            top: 39px;
            left: unset;
            right: 40px;

            width: 29px;
            height: 29px;

            &:before,
            &:after {
              width: 38px;
              left: 2px;
            }
          }

          &__layout {
            justify-content: flex-end;
            align-items: flex-end;
          }

          &__content {
            width: 800px;
            height: 370px;
            padding: 0;
          }
        }
      }
    }

    &__container {
      &._popup {
        width: 800px;
        height: 370px;
      }
    }

    &__closer {
      width: 83px;
      height: 83px;

      &:before,
      &:after {
        width: 113px;
      }
    }
  }
}

@include respond('medium') {
  .modal {
    &__container {
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100 - 163px);
    }

    &__closer {
      top: calc(-83px - var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      transform: translateY(100%);

      &._opened {
        transform: translateY(0);
      }

      &._closed {
        transform: translateY(100%);
      }
    }

    &__content {
      padding: var(--grid-spacer);
      width: 100%;
      height: 100%;
    }

    &__container {
      min-height: 50vh;
      align-self: flex-end;

      &._popup {
        min-height: unset;
      }
    }

    &__closer {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@include respond-down('small') {
  .modal {
    &__bg {
      &._popup {
        width: calc(var(--vw, 1vw) * 100 - 2 * var(--grid-spacer));
        height: 435px;
        top: unset;
        bottom: var(--grid-spacer);
        left: var(--grid-spacer);

        .modal {
          &__layout {
            justify-content: center;
          }

          &__content {
            display: flex;
            flex-direction: column;
          }

          &__closer {
            z-index: 50;
            transform: translateY(0);
            top: 19px;
            left: unset;
            right: 19px;

            width: 29px;
            height: 29px;

            &:before,
            &:after {
              width: 38px;
            }
          }
        }
      }
    }

    &__container {
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100 - 52px);

      &._popup {
        width: calc(var(--vw, 1vw) * 100 - 2 * var(--grid-spacer));
        height: 435px;
      }
    }

    &__closer {
      width: 24px;
      height: 24px;
      top: calc(-24px - var(--grid-gap));

      &:before {
        width: 32px;
      }

      &:after {
        width: 32px;
      }
    }
  }
}