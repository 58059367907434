.tabs {
  //overflow-x: scroll;
  //margin: 0 calc(var(--grid-spacer) * -1);

  list-style: none;
  display: flex;
  justify-content: flex-start;

  &__first-text {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 400;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
    color: var(--primary-color);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    a {
      display: block;
    }
  }

  &__third-text {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: 400;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
    color: var(--primary-color);
  }

  &__text {
    white-space: nowrap;
  }

  &__list {
    display: flex;

    //overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__link {
    padding: var(--tabs-link-padding);
    align-items: center;
    white-space: nowrap;
  }

  &__first {
    //Табы 1

    .tabs {
      &__icon {
        display: flex;

        svg {
          width: 100%;
          height: auto;
          opacity: .4;
          transition: opacity .4s var(--default-transition-function);
        }
      }

      &__list {
        border-radius: 50px;
        background: var(--background-color);
        width: fit-content;
        align-items: center;
        padding: var(--tabs-list-padding);
      }

      &__item {
        .tabs {
          &__link {
            display: flex;
            border-radius: 50px;
            background: var(--background-color);
            transition: background .4s var(--default-transition-function);

            .tabs__text {
              color: var(--primary-color-opacity-40);
              transition: color .4s var(--default-transition-function);
            }

            &:hover {
              .tabs__text {
                color: black;
              }

              .tabs__icon {
                svg {
                  opacity: 1;
                }
              }
            }
          }
        }

        &._active {
          .tabs {
            &__link {
              background: white;

              .tabs__text {
                color: black;
              }

              .tabs__icon {
                svg {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  &__second,
  &__third {
    .tabs {
      &__list {
        display: flex;
      }

      &__text {
        color: var(--primary-color-opacity-40);
        transition: color var(--default-timing) var(--default-transition-function);
      }

      &__item {
        &._active {
          .tabs__text {
            color: black;
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .tabs {
    &__first-text {
      --font-size: 30px;
      line-height: 110%;
    }

    &__third-text {
      --font-size: 30px;
      line-height: 110%;
    }

    &__first {
      //Табы 1
      --tabs-link-padding: 10px 20px;

      .tabs {
        &__list {
          --tabs-list-padding: 6px;
        }
      }
    }

    &__second {
      --tabs-link-padding: 19px 0 19px 40px;

      .tabs {
        &__list {
          width: calc(var(--grid-column2) - var(--grid-gap));
        }

        &__link {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            background: black;
            opacity: 0;
            top: 14px;
            left: 0;
            width: 6px;
            height: 35px;
            transition: opacity var(--default-timing) var(--teaser-transition-func);
          }
        }

        &__item {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            background: #999;
            top: 0;
            right: 0;
            width: calc(100% - 40px);
            height: 1px;
            transition: background var(--default-timing) var(--teaser-transition-func);
          }

          &:after {
            position: absolute;
            content: '';
            background: #999;
            bottom: -1px;
            right: 0;
            width: calc(100% - 40px);
            height: 1px;
            transition: background var(--default-timing) var(--teaser-transition-func);
          }

          &._active {
            &:before,
            &:after {
              background: black;
              z-index: 100;
            }

            .tabs {
              &__link {
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    &__second,
    &__third {
      .tabs {
        &__list {
          flex-direction: column;
        }

        &__item {
          &:hover {
            .tabs {
              &__text {
                color: black;
              }
            }
          }
        }
      }
    }

    &__third {
      --tabs-link-padding: 7px 0 7px 40px;

      &.tabs {
        &:before {
          position: absolute;
          content: '';
          background: var(--primary-color-opacity-40);
          top: 7px;
          left: 0;
          width: 1px;
          height: calc(100% - 14px);
        }
      }

      .tabs {
        &__list {
          position: relative;
        }

        &__item {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            background: black;
            top: 10px;
            left: 0;
            width: 6px;
            height: 27px;
            opacity: 0;
            transition: opacity var(--default-timing) var(--teaser-transition-func);
          }

          &._active {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@include respond-up("medium") {
  .tabs {
    &__first {
      .tabs {
        &__icon {
          margin-right: 20px;
          width: 30px;
          height: 24px;
        }
      }
    }
  }
}

@include respond("medium") {
  .tabs {
    &__first-text {
      --font-size: 25px;
      line-height: 110%;
    }

    &__third-text {
      --font-size: 25px;
      line-height: 110%;
    }

    &__second,
    &__third {
      --tabs-link-padding: 30px 15px;

      .tabs {
        &__list {
          margin: 0 -15px;

          &:before {
            left: calc(11px + var(--tabs-list-padding));
          }
        }

        &__item {
          &:before {
            left: 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
}

@include respond-down("medium") {
  .tabs {
    padding-left: var(--grid-spacer);
    padding-right: var(--grid-spacer);
    margin-left: calc(0px - var(--grid-spacer));
    margin-right: calc(0px - var(--grid-spacer));
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &__first {
      //Табы 1
      --tabs-link-padding: 8px 16px;
      --tabs-list-padding: 4px;
    }

    &__second,
    &__third {
      --tabs-list-padding: 4px;

      .tabs {
        &__list {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            bottom: 0;
            height: 1px;
            width: calc(100% - (4 * var(--tabs-list-padding)));
            min-width: calc(100vw - 2 * var(--grid-spacer));
            background: var(--primary-color-opacity-40);
          }
        }
      }

      .tabs {
        &__item {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            height: 3px;
            bottom: 0;
            transition: background var(--default-timing) var(--default-transition-function);
          }

          &._active {
            &:before {
              background: black;
            }
          }
        }
      }
    }
  }
}

@include respond-down("small") {
  .tabs {
    &__first-text {
      --font-size: 16px;
      line-height: 140%;
    }

    &__third-text {
      --font-size: 16px;
      line-height: 140%;
    }

    &__first {
      .tabs {
        &__icon {
          margin-right: 12px;
          width: 20px;
          height: 16px;
        }
      }
    }

    &__second,
    &__third {
      --tabs-link-padding: 20px 9px;

      .tabs {
        &__list {
          margin: 0 -9px;

          &:before {
            left: calc(5px + var(--tabs-list-padding));
          }
        }

        &__item {
          &:before {
            left: 9px;
            width: calc(100% - 18px);
          }
        }
      }
    }
  }
}

@include respond-down('x-small') {
  .tabs {
    &__first {
      .tabs {
        &__list {
          flex-direction: column;
          border-radius: 23px;
        }

        &__item {
          align-items: center;
          display: flex;
        }
      }
    }
  }
}
