.payment {
  &__title-wrap {
    overflow: hidden;

    &.animate {
      .payment__title {
        transform: translateY(0);
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__text-block {
    opacity: 0;
    height: 0;
    transition: var(--default-transition);
    z-index: -100;
  }

  &__item {
    transition: var(--default-transition);
    position: relative;
    grid-row-gap: 0;
    border-bottom: 1px solid black;
    overflow: hidden;
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        .payment {
          &__position,
          &__link-text {
            transition-delay: calc(#{$i} * 0.1s);
          }
        }
      }
    }

    &:before {
      position: absolute;
      content: '';
      background: black;
      right: 0;
    }

    &:after {
      position: absolute;
      content: '';
      background: black;
      transition: var(--default-transition);
    }

    &._opened {
      &:after {
        transform: rotate(90deg);
      }

      .payment {
        &__text-block {
          height: auto;
          opacity: 1;
          z-index: 0;
        }

        &__text {
          height: inherit;
        }
      }
    }
  }

  &__image-wrap {
    background: var(--background-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__position,
  &__link-text {
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);
    transform: translateY(100%);
    opacity: 0;
  }

  &__list {
    border-top: 1px solid black;

    &.animate {
      .payment {
        &__position,
        &__link-text {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 24px;
    height: 24px;
  }
}

@include respond-up('s-large') {
  .payment {
    margin-top: 180px;

    &__list {
      margin-top: 50px;
    }

    &__item {
      &._opened {
        padding-bottom: 80px;

        .payment {
          &__text-block {
            margin-top: 45px;
          }
        }
      }
    }

    &__link {
      grid-column: 1/8;
    }

    &__text-block {
      grid-column: 4/7;
    }

    &__position {
      flex: 0 0 calc(var(--grid-column2) + var(--grid-gap))
    }

    &__icon {
      flex: 0 0 var(--grid-column);
      display: flex;
      justify-content: flex-end;
    }

    &__link-text {
      margin-left: var(--grid-gap);
      padding-right: var(--grid-gap);
    }

    &__button-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .payment {
    &__image-wrap {
      width: 63px;
      height: 63px;
    }

    &__item {
      padding: 20px 0;

      &:before {
        width: 20px;
        height: 2px;
        top: 51px;
      }

      &:after {
        width: 2px;
        height: 20px;
        top: 42px;
        right: 9px;
      }
    }

    &__link {
      padding: 20px 0;
      margin: -20px 0;
    }
  }
}

@include respond('medium') {
  .payment {
    margin-top: 100px;

    &__list {
      margin-top: 30px;
    }

    &__item {
      &._opened {
        padding-bottom: 70px;

        .payment {
          &__text-block {
            margin-top: 33px;
          }
        }
      }
    }

    &__link {
      grid-column: 1/6;
    }

    &__text-block {
      grid-column: 2/6;
    }

    &__icon {
      flex: 0 0 var(--grid-column);
      display: flex;
      justify-content: flex-start;
    }

    &__link-text {
      margin-left: var(--grid-gap);
      padding-right: var(--grid-gap);
    }

    &__button-wrap {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .payment {
    &__position {
      display: none;
    }
  }
}

@include respond-down('small') {
  .payment {
    margin-top: 47px;

    &__list {
      margin-top: 14px;
    }

    &__item {
      &._opened {
        padding-bottom: 30px;

        .payment {
          &__text-block {
            margin-top: 20px;
          }
        }
      }
    }

    &__link,
    &__text-block {
      grid-column: 1/5;
    }

    &__icon {
      flex: 0 0 var(--grid-column);
      display: flex;
      justify-content: flex-start;
    }

    &__link-text {
      margin-left: var(--grid-gap);
      padding-right: var(--grid-gap);
    }

    &__button-wrap {
      margin-top: 20px;
    }

    &__image-wrap {
      width: 50px;
      height: 50px;
    }

    &__item {
      padding: 7px 0;

      &:before {
        width: 16px;
        height: 2px;
        top: 31px;
      }

      &:after {
        width: 2px;
        height: 16px;
        top: 24px;
        right: 7px;
      }
    }

    &__link {
      padding: 7px 0;
      margin: -7px 0;
    }
  }
}