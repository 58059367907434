.social-icons {
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: var(--icon-size);
  height: var(--icon-size);


  &__wrap {
    margin: -5px;

    > * {
      margin: 5px;
    }

    &_vertical {
      margin: -5px;

      > * {
        margin: 5px;
      }
    }
  }

  &__icon {
    width: var(--icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 0 0 var(--icon-size);
  }

  &__wrap,
  &__wrap_vertical {
    display: flex;
  }

  &__wrap_vertical {
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .social-icons {
    overflow: hidden;

    &:hover {
      .social-icons {
        &__icon {
          transform: translateY(50%);
        }
      }
    }

    &__icon {
      transition: var(--default-transition);
      transform: translateY(-50%);
    }
  }
}

@include respond-up('medium') {
  .social-icons {
    --icon-size: 47px;
  }
}

@include respond('medium') {
  .social-icons {
    &__icon {
      &_clone {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .social-icons {
    --icon-size: 43px;

    &__wrap {
      &_vertical {
        display: flex;
        flex-direction: column;
      }
    }

    &__icon {
      &_clone {
        display: none;
      }
    }
  }
}
