.hero {
  --parallax-speed: 125px;

  &__info-description {
    transition-timing-function: var(--teaser-transition-func);
    transition-duration: .5s;
    transform: translateY(10px);
    opacity: 0;
  }

  &__image-wrap {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    &.need-animation {
      .hero {
        &__image-wrap-inner {
          height: 0;
        }
      }
    }
  }

  &__image-wrap-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: height 1.2s var(--teaser-transition-func);
  }

  &__parallax-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  &__image {
    transition: transform 1.2s var(--teaser-transition-func);
  }

  &.need-animation {
    .hero {
      &__title-main {
        svg {
          path {
            transform: translate3d(0, 101%, 0);
          }
        }
      }

      &__title-address {
        svg {
          transform: translate3d(0, 101%, 0);
        }
      }

      &__button {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
      }
    }
  }

  &__main-text-clean {
    position: relative;
  }

  &__main-text {
    position: relative;

    &.animate {
      &:before {
        transform: translateY(0);
        opacity: 1;
      }

      .hero {
        &__main-text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__info-text {
    transform: translateY(100%);
    transition: transform .8s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__main-text-inner,
  &__info-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  &__main-text-inner {
    opacity: 0;
    transform: translateY(100%);
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }

    #text-wrap-line {
      position: relative;
      display: inline-block;
      color: var(--t);

      &::before {
        content: '';
        position: absolute;
        background: black;
        height: 2px;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &__button {
    transition: transform .5s, opacity .5s;
    transition-delay: 3.2s;
    transition-timing-function: var(--teaser-transition-func);
  }

  &__projects-link {
    display: flex;
    align-items: center;
    padding: 20px 0 20px 0;
    margin: -20px 0 -20px 0;
    transition: opacity .5s var(--teaser-transition-func);
  }

  &__link-text {
    margin-left: 12px;
  }

  &__title-main {
    display: flex;
    overflow: hidden;

    svg {
      height: 100%;
      width: 100%;

      path {
        transition: transform .5s var(--teaser-transition-func);
        -webkit-transition: transform .5s var(--teaser-transition-func);

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: calc(2.3s + (#{$i} * 0.1s));
            -webkit-transition-delay: calc(2.3s + (#{$i} * 0.1s));
          }
        }
      }
    }
  }

  &__title-address {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    svg {
      transition: transform .5s 2.9s var(--teaser-transition-func);
      -webkit-transition: transform .5s 2.9s var(--teaser-transition-func);
    }
  }

  &__picture {
    display: flex;
    transition: transform 1.2s var(--teaser-transition-func);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &._not-first-visit {
    .hero {
      &__title-main svg path {
        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: calc(#{$i} * 0.1s);
            -webkit-transition-delay: calc(#{$i} * 0.1s);
          }
        }
      }

      &__title-address svg,
      &__button {
        transition-delay: 0s;
      }
    }
  }
}

@include respond-up('s-large') {
  .hero {
    margin-top: 80px;

    &__image-wrap,
    &__parallax-container {
      height: calc(var(--grid-column7) * 969 / 1720);
    }

    &__picture {
      //height: calc(var(--grid-column7) * 969 / 1720 + var(--parallax-speed));
      height: calc(var(--grid-column7) * 1123 / 1720);
      //transform: translateY(calc(0px - var(--parallax-speed)));
    }

    &__projects-link {
      grid-column: 1/3;

      &:hover {
        .hero {
          &__link-arrow {
            transform: translateX(-10px);
          }
        }
      }
    }

    &__link-arrow {
      transition: var(--default-transition);
    }

    &__wrap {
      grid-row-gap: 30px;
    }

    &__title {
      grid-column: 1/4;
    }

    &__title-address {
      margin-top: calc((30 / 1920) * 100vw);
      margin-right: -10px;

      svg {
        width: calc((302 / 1920) * 100vw);
        height: calc((10 / 1920) * 100vw);
      }
    }

    &__text {
      grid-column: 5/8;
    }

    &__main-text-inner {
      #text-wrap-line {
        width: 54px;
        margin-right: 20px;

        &::before {
          width: 54px;
        }
      }
    }

    &__button {
      margin-top: 40px;
    }

    &__image-wrap {
      margin-top: 120px;
    }

    &__info-wrap {
      margin-top: 60px;
      padding: 40px 0;
    }

    &__info-wrap {
      border-bottom: 1px solid black;
    }

    &__info-item {
      &:first-child {
        grid-column: 1/4;
      }

      &:nth-child(2) {
        grid-column: 4/6;
      }

      &:nth-child(3) {
        grid-column: 6/8;
      }

      &:nth-child(2),
      &:nth-child(3) {
        position: relative;
        padding-left: 40px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -40px;
          height: 200px;
          width: 1px;
          background: black;
        }
      }
    }

    &__info-description {
      margin-top: 25px;
    }
  }
}

@include respond('medium') {
  .hero {
    margin-top: 60px;

    &__image-wrap,
    &__parallax-container {
      height: calc(var(--grid-column5) * 530 / 754);
    }

    &__picture {
      height: calc(var(--grid-column5) * 612 / 754);
    }

    &__projects-link {
      grid-column: 1/3;
    }

    &__wrap {
      grid-row-gap: 0;
    }

    &__title {
      grid-column: 1/5;
      margin-top: 30px;
    }

    &__title {
      grid-column: 1/5;
    }

    &__title-address {
      margin-top: calc((29 / 834) * 100vw);
      margin-right: -15px;

      svg {
        width: calc((279 / 834) * 100vw);
        height: calc((9 / 834) * 100vw);
      }
    }

    &__text {
      grid-column: 1/5;
      margin-top: 50px;
    }

    &__button {
      margin-top: 30px;
    }

    &__main-text-inner {
      #text-wrap-line {
        width: 46px;
        margin-right: 18px;

        &::before {
          width: 46px;
        }
      }
    }

    &__image-wrap {
      margin-top: 80px;
    }

    &__info-wrap {
      margin-top: 50px;
    }

    &__info-list {
      grid-row-gap: 0;
    }

    &__info-item {
      padding-bottom: 30px;

      &:first-child {
        grid-column: 1/6;
        border-bottom: 1px solid black;
      }

      &:nth-child(2) {
        grid-column: 1/4;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: var(--grid-column5);
          background: black;
        }
      }

      &:nth-child(3) {
        grid-column: 4/6;
        position: relative;
        padding-left: 30px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 136px;
          width: 1px;
          background: black;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 18px;
        margin-top: 60px;
      }
    }

    &__info-description {
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .hero {
    &__info-list {
      &.animate {
        .hero {
          &__info-item {
            .hero {
              &__info-text {
                transform: translateY(0);
              }

              &__info-description {
                transform: translateY(0);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__info-item {
      &:first-child {
        .hero {
          &__info-description {
            transition-delay: 0.3s;
          }
        }
      }

      &:nth-child(2) {
        .hero {
          &__info-text {
            transition-delay: 0.4s;
          }

          &__info-description {
            transition-delay: 0.7s;
          }
        }
      }

      &:nth-child(3) {
        .hero {
          &__info-text {
            transition-delay: 0.8s;
          }

          &__info-description {
            transition-delay: 1.1s;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .hero {
    margin-top: 20px;

    &__image-wrap,
    &__parallax-container {
      height: calc(var(--grid-column4) * 235 / 334);
    }

    &__picture {
      height: calc(var(--grid-column4) * 271 / 334);
    }

    &__info-item {
      &.animate {
        .hero {
          &__info-text {
            transform: translateY(0);
          }

          &__info-description {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }

    &.need-animation {
      .hero {
        &__bottom-info-wrap {
          background-color: var(--t);
        }

        &__bottom-date-wrap {
          border-bottom-color: var(--t);
        }
      }
    }

    &__projects-link {
      grid-column: 1/4;
    }

    &__wrap {
      grid-row-gap: 0;
    }

    &__title {
      grid-column: 1/4;
      margin-top: 20px;

      svg {
        height: 100%;
      }
    }

    &__title-address {
      margin-top: 12px;

      svg {
        width: calc((190 / 375) * 100vw);
        height: calc((7 / 375) * 100vw);
      }
    }

    &__text {
      grid-column: 1/5;
      margin-top: 40px;
    }

    &__button {
      margin-top: 20px;
    }

    &__main-text-inner {
      #text-wrap-line {
        width: 26px;
        margin-right: 12px;

        &::before {
          width: 26px;
        }
      }
    }

    &__image-wrap {
      margin-top: 40px;
    }

    &__info-wrap {
      margin-top: 30px;
    }

    &__info-list {
      grid-row-gap: 0;
    }

    &__info-item {
      padding-bottom: 14px;
      border-bottom: 1px solid black;
      grid-column: 1/5;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__info-description {
      margin-top: 10px;
      transition-delay: 0.3s;
    }
  }
}
