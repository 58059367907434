.architecture {
  &__title-wrapper {
    overflow: hidden;

    &.animate {
      .architecture__title {
        transform: translateY(0);
      }
    }
  }

  &__title {
    transform: translateY(100%);
    transition: transform 0.8s var(--teaser-transition-func);
  }

  &__slider-wrap {
    margin-right: calc(0px - var(--grid-spacer-and-indent));
    margin-left: calc(0px - var(--grid-spacer-and-indent));
  }

  &__slider-list {
    padding-left: var(--grid-spacer-and-indent);
    cursor: grab;
  }

  &__slider {
    &.swiper {
      padding-right: calc(var(--grid-spacer-and-indent) * 2);
    }
  }

  &__image-wrap {
    position: relative;
  }

  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item-name {
    padding: 30px 0 25px 40px;
    border-left: 1px solid black;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 1px;
      width: calc(100% + var(--grid-gap));
      background: black;
    }
  }

  &__slider-item {
    &:last-child {
      .architecture {
        &__item-name {
          border-right: 1px solid black;

          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  &__slider-item {
    &:nth-child(even) {
      .architecture {
        &__image-wrap {
          padding-bottom: 67%;
        }
      }
    }

    &:nth-child(odd) {
      .architecture {
        &__image-wrap {
          padding-bottom: 113%;
        }
      }
    }

    &:first-child {
      .architecture {
        &__image-wrap {
          padding-bottom: 92%;
        }
      }
    }
  }

}

@include respond-up('s-large') {
  .architecture {
    padding-top: 180px;

    &__title-wrapper {
      grid-column: 1/6;
    }

    &__slider-nav {
      grid-column: 7/8;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__slider-wrap {
      margin-top: 50px;
    }

    &__item-name {
      padding: 30px 0 25px 40px;
    }

    &__slider-item {

      &:not(:first-child) {
        margin-left: var(--grid-gap);

        &.swiper-slide {
          width: var(--grid-column2);
        }
      }

      &:first-child {
        &.swiper-slide {
          width: var(--grid-column3);
        }
      }
    }

    &__image-wrap {
      margin-top: 40px;
    }

    &__item-description {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .architecture {
    &__nav {
      display: flex;
    }
  }
}

@include respond('medium') {
  .architecture {
    padding-top: 120px;

    &__title-wrapper {
      grid-column: 1/5;
    }

    &__slider-nav {
      grid-column: 5/6;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__slider-wrap {
      margin-top: 30px;
    }

    &__item-name {
      padding: 20px 0 16px 30px;
    }

    &__slider-item {

      &:not(:first-child) {
        margin-left: var(--grid-gap);

        &.swiper-slide {
          width: var(--grid-column3);
        }
      }

      &:first-child {
        &.swiper-slide {
          width: var(--grid-column4);
        }
      }
    }

    &__image-wrap {
      margin-top: 30px;
    }

    &__item-description {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .architecture {
    padding-top: 60px;

    &__title-wrapper {
      grid-column: 1/5;
    }

    &__slider-nav {
      display: none;
    }

    &__slider-wrap {
      margin-top: 14px;
    }

    &__item-name {
      padding: 14px 0 12px 20px;
    }

    &__slider-item {

      &.swiper-slide {
        width: var(--grid-column3);
      }

      &:not(:first-child) {
        margin-left: var(--grid-gap);
      }
    }

    &__image-wrap {
      margin-top: 14px;
    }

    &__item-description {
      margin-top: 14px;
    }
  }
}