.need-animation {
  .hero {
    &__picture {
      transform: scale(1.1);
    }

    &__image {
      transform: translate3d(148px, 0, 0);
    }

    &__main-text {
      opacity: 0;

      &::before {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
      }
    }

    &__main-text-inner {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }

    &__title-main {
      svg {
        path {
          transform: translate3d(0, 101%, 0);
        }
      }
    }

    &__title-address {
      svg {
        transform: translate3d(0, 101%, 0);
      }
    }

    &__button {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }

    &__bottom-text {
      &::before {
        transform: scaleY(0);
      }

      &::after {
        transform: scaleX(0);
      }
    }

    &__bottom-text-inner {
      opacity: 0;
    }

    &__bottom-date-wrap {
      &::before {
        transform: scaleX(0);
      }
    }

    &__bottom-date-title-inner {
      transform: translate3d(0, 100%, 0);
      opacity: 0;
    }

    &__bottom-date-subtitle {
      opacity: 0;
    }

    &__projects-link {
      opacity: 0;
    }
  }

  .header {
    opacity: 0;
  }

  .preloader {
    &__title {
      svg {
        path {
          transform: translate3d(0, 101%, 0);
        }
      }
    }

    &__subtitle {
      svg {
        transform: translate3d(0, 101%, 0);
      }
    }
  }
}

.animate-out {
  .hero {
    &__picture {
      transform: translateY(0) scale(1);
      //transform: scale(1) translateY(calc(0px - var(--parallax-speed)));
      opacity: 1;
    }
  }

  .preloader {
    &__wrapper {
      opacity: 0;
      z-index: 50;
    }

    &__title {
      svg {
        path {
          transform: translate3d(0, -101%, 0);

          @for $i from 1 through 10 {
            &:nth-last-child(#{$i}) {
              transition-delay: calc(#{$i} * 0.1s);
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .need-animation {
    .hero {
      &__bottom-info-wrap {
        background-color: var(--t);
      }

      &__bottom-date-wrap {
        border-bottom-color: var(--t);
      }
    }
  }
}