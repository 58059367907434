.modal-form {
  display: flex;
  flex-direction: column;
  align-self: center;

  &__title {
    overflow: hidden;
  }

  &__title-inner {
    transition: transform .5s .3s var(--teaser-transition-func);
  }

  &__subtitle,
  &__flat-info {
    transition: opacity .5s .4s, transform .5s .4s;
  }

  &__policy-link {
    border-bottom: 1px solid var(--primary-color-opacity-40);
    transition: var(--default-transition);
  }

  &__fields-wrap,
  &__bottom {
    transition: opacity .5s, transform .5s;
    transition-delay: .5s;
  }

  &._awaiting-animation {
    .modal-form {
      &__title-inner {
        transform: translate3d(0, 100%, 0);
      }

      &__subtitle,
      &__flat-info,
      &__fields-wrap,
      &__bottom {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }
    }
  }

  &._close {
    .modal-form {
      &__title-inner,
      &__subtitle,
      &__flat-info,
      &__fields-wrap,
      &__bottom {
        opacity: 0;
        transition: opacity .3s var(--teaser-transition-func);
      }
    }
  }

  &__policy {
    color: var(--primary-color-opacity-40);
    flex: 0 1 378px;
  }

  &__flat-info {
    background: var(--background-color);
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__flat-text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__image-wrap {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('s-large') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 55px;
      }
    }

    &__subtitle {
      margin-top: 40px;
      width: var(--grid-column2);
    }

    &__fields-wrap {
      margin-top: 80px;
    }

    &__flat-info {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .modal-form {

    &__bottom {
      display: flex;
      align-items: center;
    }

    &__policy {
      margin-left: 45px;
      padding-top: 3px;
    }

    &__bottom {
      margin-top: 40px;
    }

    &__flat-detail {
      margin-top: 10px;
    }

    &__flat-text-wrap {
      margin-left: 30px;
    }

    &__image-wrap {
      width: 100px;
      height: 100px;
    }
  }
}

@include respond('medium') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 45px;
      }
    }

    &__subtitle {
      margin-top: 30px;
      width: var(--grid-column4);
    }

    &__fields-wrap {
      margin-top: 70px;
    }

    &__flat-info {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .modal-form {
  }
}

@include respond-down('small') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__subtitle {
      margin-top: 20px;
    }

    &__fields-wrap {
      margin-top: 40px;
    }

    &__bottom {
      margin-top: 20px;
    }

    &__policy {
      margin-top: 20px;
      padding-top: 5px;
    }

    &__flat-info {
      margin-top: 14px;
    }

    &__flat-text-wrap {
      margin-left: var(--grid-gap);
    }

    &__image-wrap {
      width: 63px;
      height: 63px;
    }
  }
}