.hero-form-success {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 5vh;

  &__icon,
  &__title,
  &__subtitle {
    transition: opacity .5s, transform .5s;
  }

  &__icon {
    transition-delay: .3s;

    svg line {
      &:nth-child(1) {
        transition: stroke-dashoffset .2s .5s;
      }

      &:nth-child(2) {
        transition: stroke-dashoffset .5s .7s;
      }
    }
  }

  &._awaiting-animation {
    .hero-form-success {
      &__icon,
      &__title,
      &__subtitle {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
      }

      &__icon {
        svg line {
          &:nth-child(1) {
            stroke-dashoffset: 40;
          }

          &:nth-child(2) {
            stroke-dashoffset: 95;
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .hero-form-success {
    &__title {
      margin-top: 40px;
    }

    &__subtitle {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .hero-form-success {
    &__subtitle {
      max-width: var(--grid-column3);
    }

    &__icon {
      height: 112px;
    }
  }
}

@include respond('medium') {
  .hero-form-success {
    &__title {
      margin-top: 30px;
    }

    &__subtitle {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .hero-form-success {
    &__title {
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 20px;
    }

    &__icon {
      height: 82px;

      svg {
        width: 82px;
        height: 82px;
      }
    }
  }
}