.share {
  height: fit-content;
  width: fit-content;

  &__wrap {
    display: flex;
  }
}

.share_article {
  .ya-share2 {
    &__container {
      .ya-share2 {
        &__list {
          .ya-share2 {
            &__item {
              .ya-share2 {
                &__link {
                  background: white;

                  .ya-share2 {
                    &__badge {
                      display: flex;
                      flex-direction: column;
                      transition: transform .4s ease-in-out;

                      .ya-share2 {
                        &__icon {
                          background-image: url("../../templates/_parts/icons/share.svg");
                        }
                      }

                      &_more {
                        .ya-share2 {
                          &__icon_more {
                            background-size: 18px;
                          }
                        }
                      }
                    }
                  }
                }

                &__popup {
                  .ya-share2 {
                    &__list {
                      .ya-share2 {
                        &__item {
                          .ya-share2 {
                            &__link {
                              .ya-share2 {
                                &__title {
                                  display: none;
                                }
                              }
                            }
                          }

                          &:first-child {
                            .ya-share2 {
                              &__link {
                                .ya-share2 {
                                  &__badge {
                                    .ya-share2 {
                                      &__icon {
                                        background-image: url("../../templates/_parts/icons/vk-icon.svg");
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }

                          &:nth-child(2) {
                            .ya-share2 {
                              &__link {
                                .ya-share2 {
                                  &__badge {
                                    .ya-share2 {
                                      &__icon {
                                        background-image: url("../../templates/_parts/icons/ok-icon.svg");
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .share-print {
    overflow: hidden;

    &:hover {
      .share-print {
        &__icon {
          transform: translateY(0);
        }
      }
    }

    &__icon {
      transition: var(--default-transition);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateY(-42px);

      &_clone {
        margin-top: 20px;
      }
    }
  }

  .share_article {
    .ya-share2 {
      &__popup {
        width: 100%;

        .ya-share2 {
          &__badge {
            transform: translateY(-100%);

            &_copy {
              transform: translateY(-50%);
            }
          }

          &__item {
            &:hover {
              .ya-share2 {
                &__badge {
                  transform: translateY(50%);

                  &_copy {
                    transform: translateY(100%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .share-print {
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 55px;
    padding-top: 14px;
  }

  .share {
    &__fav {
      margin-bottom: 18px;
    }

    &__wrap {
      flex-direction: column;
    }
  }


  .share_article {
    .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more.ya-share2__link_more-button-type_short {
      border: 1px solid black;
      padding: 0;
      border-radius: 50%;
      background: white;

      .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__badge_more {
        background: white !important;
        background: red;
      }
    }

    margin-top: 12px;

    .ya-share2 {
      &__container {
        .ya-share2 {
          &__list {
            .ya-share2 {
              &__item {
                position: relative;

                .ya-share2 {
                  &__link {
                    width: 55px;
                    height: 55px;
                    border: 1px solid black;
                    padding: 0;
                    background: white;
                    border-radius: 50%;
                    text-align: center;

                    &_more {
                      position: relative;
                      z-index: 1500;
                    }

                    .ya-share2 {
                      &__badge_more {
                        .ya-share2 {
                          &__icon_more {
                            height: 55px;
                            width: 55px;
                            background-position-x: 17px;
                            background-position-y: 16px;
                          }
                        }
                      }
                    }
                  }

                  &__popup {
                    position: absolute;
                    z-index: 1300;
                    top: 55px;
                    transition: transform .4s ease-in-out;
                    box-shadow: none;
                    border-radius: 0;
                    background: white;

                    .ya-share2__list {
                      position: relative;

                      @for $i from 1 through 11 {
                        .ya-share2__item {

                          &:nth-child(#{$i}) {
                            transition-delay: calc(#{$i} * .08s);
                            z-index: calc(1200 - #{$i} * 100);
                          }
                        }
                      }
                    }

                    .ya-share2__item {
                      position: absolute;
                      z-index: 1400;
                      top: 0;
                      left: 4px;
                      transform: translateY(-50px);
                      opacity: 0;
                      transition: opacity .8s ease-in-out, transform .4s ease-in-out;
                      background: white;
                      border-radius: 50%;
                    }

                    &_visible {
                      &_local {
                        transform: translateY(20px);

                        .ya-share2__item {
                          opacity: 1;
                        }

                        .ya-share2__list {
                          @for $i from 1 through 11 {
                            .ya-share2__item {

                              &:nth-child(#{$i}) {
                                transform: translateY(calc((#{$i} - 1) * 55px));
                              }
                            }
                          }
                        }
                      }
                    }

                    .ya-share2 {
                      &__list {
                        padding: 0;

                        .ya-share2 {
                          &__item {
                            padding: 0;

                            &:hover {
                              background: rgba(0, 0, 0, 0);
                            }

                            .ya-share2 {
                              &__link {
                                border-radius: 50%;
                                width: 47px;
                                height: 47px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                overflow: hidden;
                                flex-direction: column;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .share-print {
    &__icon {
      &_clone {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .share-print {
    order: 2;
    margin-left: 12px;
  }

  .share_article {
    .ya-share2__container_size_m .ya-share2__popup:not(.ya-share2__popup_mobile) {
      box-shadow: none;
      margin-left: -8px;
    }

    .ya-share2__container .ya-share2__list .ya-share2__item .ya-share2__link{
      padding: 0 10px;
      margin: 0 -10px;
    }

    .ya-share2__container_size_m .ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__link_more.ya-share2__link_more-button-type_short {
      padding: 12px;
      margin: -12px;
      background: white;
    }
  }
}